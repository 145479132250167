import { BaseApiRequest } from '@api/BaseApi';
import moment from 'moment-timezone';
import { getToken } from '@store/helpers';
import { DATE_FORMAT, UTC_TIMESTAMP } from '@constants';
import { getOffset } from '@api/helpers';

export const fetchDayReportData = (signal, { selectedRig, reportDate, well = null, token }) => {
  return BaseApiRequest(signal, `/data`, 'ddr', 'POST', {
    rigname: selectedRig.rigName,
    rigId: selectedRig.rigId,
    reportdate: moment(reportDate).format(DATE_FORMAT),
    wellname: well,
    token: getToken() || token
  });
};

export const fetchHourReportData = (
  signal,
  { selectedRig, startDate, endDate, well = null, token }
) => {
  return BaseApiRequest(signal, `/events`, 'ddr', 'POST', {
    rigname: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate: startDate,
    endDate: endDate,
    wellname: well,
    token: getToken() || token,
    offset: getOffset(startDate)
  });
};
export const fetchRigWells = (signal, { startDate, endDate, selectedRig, token }) => {
  return BaseApiRequest(signal, `/wells.v3`, 'ddr', 'POST', {
    rigname: selectedRig.rigName,
    startdate: startDate,
    torigtimezone: true,
    enddate: endDate,
    token: getToken() || token
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import InfoBox from '../../Display/InfoBox';
import { DISPLAY_UNIT, WARNINGS_TYPE } from '@constants';
import { useDisplayUnit } from '@hooks';

function WarningInfoBoxes({ data }) {
  const { valueWithUnit } = useDisplayUnit();
  const { type, energySaving, fuelSaving, co2Saving, noxSaving } = data || {};
  return (
    <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
      {type !== WARNINGS_TYPE.TooManyEng && (
        <InfoBox
          value={valueWithUnit(energySaving, DISPLAY_UNIT.ENERGY)}
          header={'Potential energy savings'}
        />
      )}
      <InfoBox
        value={valueWithUnit(fuelSaving, DISPLAY_UNIT.FUEL)}
        header={'Potential fuel savings'}
      />
      <InfoBox
        value={valueWithUnit(co2Saving, DISPLAY_UNIT.CO2)}
        header={
          <Typography variant={'subtitle1'}>
            Potential CO<sub>2</sub>e reduction
          </Typography>
        }
      />
      {type !== WARNINGS_TYPE.TooManyEng && (
        <InfoBox
          value={valueWithUnit(noxSaving, DISPLAY_UNIT.NOX)}
          header={
            <Typography variant={'subtitle1'}>
              Potential NO<sub>x</sub> reduction
            </Typography>
          }
        />
      )}
    </Stack>
  );
}

WarningInfoBoxes.propTypes = {
  data: PropTypes.object
};

WarningInfoBoxes.defaultProps = {
  data: null
};

export default WarningInfoBoxes;

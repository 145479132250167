import React, { useState, useEffect } from 'react';
import {
  Typography,
  Stack,
  DeleteOutlineIcon,
  EditIcon,
  IconButton,
  MenuIcon,
  Box,
  TextField,
  Button,
  CircularProgress
} from '@esgian/esgianui';
import {
  getSavedTimePeriod,
  createSavedTimePeriod,
  updateSavedTimePeriod,
  deleteSavedTimePeriod
} from '@api/TimePeriod';
import { DATE_FORMAT, TIMESTAMP, TIME_PERIOD_TABS } from '@constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getUser } from '@store/features';
import { useSelector } from 'react-redux';
import { useTimezone } from '@hooks/useTimezone';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const SavedTimePeriod = ({
  handleClose,
  onSave,
  startDate,
  endDate,
  selectedTimePeriod,
  setSelectedTimePeriod,
  setTabValue
}) => {
  const { selectedTimeZone } = useTimezone();
  const user = useSelector(getUser);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchSaveTimePeriod();
  }, []);

  const fetchSaveTimePeriod = async () => {
    await getSavedTimePeriod()
      .then((result) => setList(result.sort((a, b) => a.rank - b.rank)))
      .catch(() => setList([]));
    setLoading(false);
  };

  const upsertTimePeriod = async () => {
    setLoading(true);
    if (selectedTimePeriod.id) {
      await updateSavedTimePeriod({
        ...selectedTimePeriod,
        name: selectedTimePeriod.name
      })
        .then((result) => setList(result))
        .catch(() => setList([]));
    } else {
      await createSavedTimePeriod({
        userId: user.id,
        name: selectedTimePeriod.name,
        startDate: moment(selectedTimePeriod.startDate).startOf('day').format(TIMESTAMP),
        endDate: moment(selectedTimePeriod.endDate).endOf('day').format(TIMESTAMP),
        rank: list?.[list?.length - 1]?.rank + 1 || 1
      })
        .then((result) => setList(result))
        .catch(() => setList([]));
      setTabValue(TIME_PERIOD_TABS.DATA_PICKER);
    }

    setSelectedTimePeriod(null);
    await fetchSaveTimePeriod();
  };

  const removeSaveTimePeriod = async (item) => {
    setLoading(true);
    await deleteSavedTimePeriod(item.id)
      .then((result) => setList(result))
      .catch(() => setList([]));
    await fetchSaveTimePeriod();
  };

  const onDragEnd = async ({ destination, source }) => {
    const newItems = reorder(list, source.index, destination.index);
    const promiseList = [];
    setList(
      newItems?.map((baseline, i) => {
        return { ...baseline, rank: i + 1 };
      })
    );

    newItems.forEach((item) => {
      promiseList.push(
        updateSavedTimePeriod({
          ...item
        })
      );
    });
    await Promise.all(promiseList).finally(() => setLoading(false));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleEditClick = (item) => {
    setSelectedTimePeriod(item);
  };

  const handleInputChange = (event) => {
    setSelectedTimePeriod({
      ...selectedTimePeriod,
      name: event.target.value
    });
  };

  const handleItemClick = (item) => {
    onSave({
      startDate: moment(item.startDate)
        .tz(selectedTimeZone, true)
        .startOf('day')
        .format(DATE_FORMAT),
      endDate: moment(item.endDate).tz(selectedTimeZone, true).endOf('day').format(DATE_FORMAT),
      name: item.name || null
    });
    handleClose();
  };

  return loading ? (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ overflow: 'auto', height: 448 }}>
      {!selectedTimePeriod ? (
        <DragDropContext onDragEnd={onDragEnd}>
          {list?.length ? (
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {list?.map((item, index) => {
                    const isSelected =
                      item.startDate.split('T')[0] === startDate &&
                      item.endDate.split('T')[0] === endDate;
                    return (
                      <Draggable
                        key={`item-${item.id}`}
                        draggableId={`drag-${item.id}`}
                        index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <Stack
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  backgroundColor: ({ palette }) => palette.neutral.neutral02
                                },
                                pt: 0.5,
                                pb: 0.5
                              }}
                              direction={'row'}
                              justifyContent={'space-between'}>
                              <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                sx={{ width: '100%' }}>
                                <MenuIcon
                                  fontSize={'16px'}
                                  sx={{
                                    color: ({ palette }) =>
                                      isSelected
                                        ? palette.primary.main
                                        : palette.secondary.contrastText
                                  }}
                                />
                                <Typography
                                  sx={{
                                    width: '100%',
                                    color: ({ palette }) =>
                                      isSelected
                                        ? palette.primary.main
                                        : palette.secondary.contrastText
                                  }}
                                  onClick={() => handleItemClick(item)}
                                  variant={'body2'}>
                                  {item.name}
                                </Typography>
                              </Stack>
                              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <IconButton
                                  size={'small'}
                                  sx={{
                                    color: ({ palette }) =>
                                      isSelected
                                        ? palette.primary.main
                                        : palette.secondary.contrastText
                                  }}
                                  onClick={() => handleEditClick(item)}>
                                  <EditIcon fontSize="small" color={'inherit'} />
                                </IconButton>
                                <IconButton
                                  size={'small'}
                                  sx={{
                                    color: ({ palette }) =>
                                      isSelected
                                        ? palette.primary.main
                                        : palette.secondary.contrastText
                                  }}
                                  onClick={() => removeSaveTimePeriod(item)}>
                                  <DeleteOutlineIcon fontSize="small" color={'inherit'} />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : null}
        </DragDropContext>
      ) : (
        <Box sx={{ padding: '16px' }}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={selectedTimePeriod.name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => setSelectedTimePeriod(null)}
              variant="text"
              sx={{ color: (theme) => theme.palette.secondary.contrastText, mr: 4 }}>
              CANCEL
            </Button>
            <Button onClick={upsertTimePeriod} variant="contained" color="primary">
              SAVE
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

SavedTimePeriod.propTypes = {
  setTabValue: PropTypes.func.isRequired,
  setSelectedTimePeriod: PropTypes.func.isRequired,
  selectedTimePeriod: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

SavedTimePeriod.defaultProps = {};

export default SavedTimePeriod;

import moment from 'moment-timezone';

export const objectToQuery = (obj) => {
  let query = '';
  Object.keys(obj).map((key, i) => {
    if (i !== 0) {
      query += '&';
    }
    query += `${key}=${obj[key]}`;
  });
  return query;
};
export const getOffset = (date = '') => {
  // Parse the input date string with moment
  const dateParsed = moment.parseZone(date);

  // Get the timezone offset in the format +HH:mm or -HH:mm
  return dateParsed.format('Z');
};

import React, { useCallback, useMemo } from 'react';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { VIEW_BY_TYPE } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import {
  getBaselineComparisonSlice,
  setEquipmentFilters
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import EquipmentComparisonKpis from '@components/Sections/BaselineComparisonSection/SubSections/EquipmentComparison/EquipmentComparisonKpis';
import { BaselineComparisonChart } from '@components/Charts';
import DataTypeSelect from '../../../../Inputs/DataTypeSelect';
import LookupSelect from '@components/Inputs/LookupSelect';
import { LOOKUP_EQUIPMENT } from '../../../../../lookups';
import { useBaselineComp } from '@hooks/useBaselineComp';
import HourDaySelect from '../../../../Inputs/HourDaySelect';
import { BaselineChartTypeSelect } from '@components/Inputs';
import { useBaselineQueries } from '@hooks';

function EquipmentComparison() {
  const displayUnit = useSelector(getDisplayUnit);
  const dispatch = useDispatch();
  const { getEquipmentSeries } = useBaselineComp();
  const { baselineEquipmentQueries } = useBaselineQueries();
  const {
    equipment: { dataType, chartType, equipmentType, viewBy }
  } = useSelector(getBaselineComparisonSlice);

  const handleChange = useCallback((val) => {
    dispatch(setEquipmentFilters(val));
  }, []);
  const mainData = baselineEquipmentQueries[0].data;
  const baselineData = baselineEquipmentQueries[1].data;

  const {
    mainSeries = [],
    baseSeries = [],
    categories = [],
    unit,
    max = 0
  } = useMemo(() => {
    if (!mainData || !baselineData) return {};
    return getEquipmentSeries(mainData, baselineData);
  }, [displayUnit, mainData, baselineData, dataType, equipmentType, viewBy]);
  const loading = baselineEquipmentQueries?.some((item) => item.isFetching);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant={'h6'}>Equipment comparison</Typography>
        </Stack>
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparing to the Baseline
        </Typography>
        <EquipmentComparisonKpis
          loading={loading}
          baselineData={baselineData}
          mainData={mainData}
        />
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparison over time
        </Typography>
        <BaselineComparisonChart
          dataType={dataType}
          chartType={chartType}
          viewBy={viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour'}
          chartActions={[
            <LookupSelect
              options={LOOKUP_EQUIPMENT}
              label={'Equipment'}
              key={'equipment-select'}
              value={equipmentType}
              onChange={(val) => {
                handleChange({ equipmentType: val });
              }}
            />,
            <BaselineChartTypeSelect
              key={'baseline-chart-type-select'}
              value={chartType}
              handleChange={(val) => {
                handleChange({ chartType: val });
              }}
            />,
            <DataTypeSelect
              key={'data-type-select'}
              value={dataType}
              onChange={(val) => {
                handleChange({ dataType: val });
              }}
            />,
            <HourDaySelect
              key={'view-by-select'}
              value={viewBy}
              handleChange={(val) => {
                handleChange({ viewBy: val });
              }}
            />
          ]}
          yAxisTitle={unit}
          mainMax={max}
          loading={loading}
          mainSeries={mainSeries}
          baseSeries={baseSeries}
          categories={categories}
        />
      </Stack>
    </Paper>
  );
}

EquipmentComparison.propTypes = {};

EquipmentComparison.defaultProps = {};

export default EquipmentComparison;

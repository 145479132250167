import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  CircularProgress,
  CloseIcon,
  InfoOutlineIcon,
  Modal,
  Stack,
  Typography
} from '@esgian/esgianui';
import { RigSelect, WellSelect } from '@components/Inputs';
import { fetchRigWells } from '@api/DdrReport';
import { useSelector } from 'react-redux';
import { fetchMainPdfReport } from '@api/General';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';
import { DATE_FORMAT } from '@constants';

function ExportPdfModal({ handleClose }) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { startDate, endDate, selectedRig: stateRig } = useSelector(getMainPageSlice);
  const [selectedRig, setSelectedRig] = useState(stateRig);
  const [selectedWell, setSelectedWell] = useState(null);
  const [showDownloadError, setShowDownloadError] = useState(false);
  const controller = new AbortController();
  const { signal } = controller;

  const wellsLookup = useQuery({
    queryKey: ['lookupWells', { selectedRig: selectedRig, startDate: startDate, endDate: endDate }],
    enabled: !!selectedRig,
    placeholderData: [],
    queryFn: ({ queryKey, signal }) => {
      setSelectedWell(null);
      let start = moment(startDate).subtract(1, 'year').format(DATE_FORMAT);
      let end = moment(endDate).add(1, 'year');
      if (end.isAfter(moment())) {
        end = moment();
      }
      end = end.format(DATE_FORMAT);
      return fetchRigWells(signal, { ...queryKey[1], startDate: start, endDate: end })
        .then((result) => {
          return result
            .filter(({ starttime, endtime }) => {
              return moment(endtime).diff(moment(starttime), 'days') >= 3;
            })
            .sort((a, b) => {
              return (
                moment.parseZone(b.starttime).valueOf() - moment.parseZone(a.starttime).valueOf()
              );
            });
        })
        .catch(() => []);
    }
  });

  const handleExportClick = async () => {
    if (!selectedRig || !selectedWell) return;
    setLoadingSubmit(true);
    await fetchMainPdfReport(signal, selectedRig, selectedWell)
      .then(() => {
        handleClose();
        setShowDownloadError(false);
      })
      .catch(() => {
        setShowDownloadError(true);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  return (
    <Modal
      closeIcon={<CloseIcon fontSize={'small'} />}
      PaperProps={{
        sx: {
          background: ({ palette }) => palette.neutral.neutral01
        }
      }}
      size={'xs'}
      open
      DialogActionsProps={{ sx: { justifyContent: 'center' } }}
      handleClose={handleClose}
      actions={[
        <Button
          key={'cancel-button'}
          onClick={handleClose}
          sx={{ color: (theme) => theme.palette.secondary.contrastText }}
          color={'secondary'}
          variant={'text'}>
          Cancel
        </Button>,
        <Button
          key={'save-button'}
          disabled={wellsLookup.isFetching || !selectedWell}
          sx={{
            color: (theme) => theme.palette.secondary.contrastText,
            borderColor: (theme) => theme.palette.secondary.contrastText
          }}
          onClick={handleExportClick}
          variant={'text'}>
          export
        </Button>
      ]}
      title={'Export to report'}>
      <Stack spacing={6} py={3} px={2}>
        <Alert
          icon={
            <InfoOutlineIcon
              fontSize={'small'}
              sx={{ color: ({ palette }) => palette.neutral.primary }}
            />
          }
          sx={{
            color: ({ palette }) => palette.neutral.primary,
            backgroundColor: ({ palette }) => palette.neutral.neutral04
          }}>
          The visualisation in the report will differ slightly from that on the website.
        </Alert>
        {showDownloadError && (
          <Alert severity={'error'}>
            Unable to download well report, please try again. If the problem persists please contact
            Esgian.
          </Alert>
        )}
        {loadingSubmit ? (
          <Stack alignItems={'center'} spacing={2}>
            <CircularProgress size={50} />
            <Typography variant={'h6'}>Loading...</Typography>
          </Stack>
        ) : (
          <>
            <RigSelect
              showTitle
              selectedRig={selectedRig}
              onChange={(rig) => setSelectedRig(rig)}
              required
            />
            <WellSelect
              loading={wellsLookup.isFetching}
              lookupWells={wellsLookup.data}
              selectedWell={selectedWell}
              onChange={setSelectedWell}
            />
          </>
        )}
      </Stack>
    </Modal>
  );
}

ExportPdfModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

ExportPdfModal.defaultProps = {};

export default ExportPdfModal;

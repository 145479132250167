import { combineReducers } from '@reduxjs/toolkit';
import BaselineComparisonSlice from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import MainPageSlice from '@store/features/filters/MainPageSlice';

const FiltersReducer = combineReducers({
  baselineComparison: BaselineComparisonSlice,
  mainPage: MainPageSlice
});

export const getFiltersSlice = (state) => state.filters;

export default FiltersReducer;

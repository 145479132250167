import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Divider, Stack, Typography } from '@esgian/esgianui';
import { LegendValue } from '@components';
import { useTheme } from '@hooks';
import moment from 'moment-timezone';
import CustomTooltip from '@components/Charts/BarChartWithCustomTooltip/CustomTooltip/CustomTooltip';
import ApexCharts from 'apexcharts';
import { parseNumberToSignificant } from '@helpers';

const handleMouseMove = (event, config, setShowTooltip, setTooltipPosition) => {
  if (config.dataPointIndex > -1) {
    setShowTooltip(config.dataPointIndex);
    let leftP = event.pageX - 400;
    if (event.pageX < 400) {
      leftP = event.pageX;
    }
    setTooltipPosition((prevPos) => ({ ...prevPos, left: leftP }));
  } else {
    setShowTooltip(null);
  }
};

const getOptions = (
  theme,
  isPrimary,
  categories,
  group,
  setShowTooltip,
  setPositions,
  unit,
  index,
  primaryColorsOrder = [],
  secondaryMax
) => {
  const {
    palette: {
      mode,
      charts: { background, sixColorsLine, twoColorsContrast },
      error: { light },
      text: { primary }
    }
  } = theme;
  let colors = [];
  categories?.forEach((cat, i) => {
    if (i >= 30 && i <= categories.length - 31) {
      colors.push(light);
    } else {
      colors.push(primary);
    }
  });
  let primaryColors = sixColorsLine;
  let primStroke = 1;
  if (primaryColorsOrder?.length) {
    primaryColors = primaryColorsOrder?.map(({ index }) => sixColorsLine[index]);
    primStroke = primaryColorsOrder?.map(({ opacity }) => opacity);
  }

  return {
    chart: {
      animations: {
        enabled: false
      },
      id: isPrimary ? '-load-main-' + index : '-load-secondary-' + index,
      type: 'bar',
      height: 250,
      parentHeightOffset: 0,
      background: background,
      group: group,
      toolbar: {
        show: isPrimary,
        tools: {
          reset: false,
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true
        }
      },
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),

      events: {
        mouseMove: function (event, chartContext, config) {
          const interval = setTimeout(() => {
            handleMouseMove(event, config, setShowTooltip, setPositions);
            clearInterval(interval);
          }, 100);
        }
      }
    },
    colors: isPrimary ? primaryColors : twoColorsContrast,
    theme: {
      mode: mode
    },

    dataLabels: {
      enabled: false
    },

    tooltip: {
      shared: true,
      intersect: false
    },
    grid: {
      padding: { top: isPrimary ? 10 : 0 }
    },
    yaxis: {
      labels: {
        minWidth: 40,
        maxWidth: 40,
        style: { fontSize: '10px' }
      },
      title: {
        text: isPrimary ? unit : undefined,
        offsetY: -100,
        offsetX: 25,
        rotate: 0,
        style: {
          fontSize: '10px',
          fontWeight: 100
        }
      },
      max: (max) => (isPrimary ? max * 1.2 : secondaryMax > 5 ? secondaryMax : 5),
      min: (min) => (isPrimary ? min : 0),
      tickAmount: 5,
      decimalsInFloat: 0,
      forceNiceScale: true
    },
    stroke: {
      width: 2,
      curve: 'straight'
    },
    fill: {
      colors: isPrimary ? primaryColors : twoColorsContrast,
      opacity: isPrimary ? primStroke : [0.4, 0.4],
      type: isPrimary ? ['solid', 'solid', 'solid', 'solid'] : 'solid'
    },
    xaxis: {
      type: 'datetime',
      axisTicks: {
        show: false
      },
      tooltip: {
        enabled: false
      },
      crosshairs: {
        show: false
      },
      labels: {
        show: true,
        rotate: -45,
        min: 0,
        rotateAlways: true,
        minHeight: 30,
        maxHeight: 30,
        offsetY: -10,
        style: {
          fontSize: '10px',
          fontWeight: 100
        },
        datetimeUTC: true,

        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
          minute: 'HH:mm'
        }
      }
    },
    legend: {
      show: true,
      markers: { radius: 0, width: 8, height: 8 },
      position: 'top',
      horizontalAlign: 'left'
    }
  };
};

function GroupedLineWithCustomTooltip({
  loading,
  mainSeries,
  secondarySeries,
  categories,
  secondaryMax,
  tooltipId,
  primaryTitle,
  secondaryTitle,
  unit,
  primaryColorsOrder,
  index
}) {
  const [showTooltip, setShowTooltip] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const [tempTooltipPosition, setTempTooltipPosition] = useState({ left: 0, top: 0 });
  const { theme } = useTheme();

  const mainChartRef = useRef(null);
  const brushChartRef = useRef(null);

  useEffect(() => {
    if (!categories) return {};
    if (mainChartRef.current) {
      mainChartRef.current.destroy();
    }
    if (brushChartRef.current) {
      brushChartRef.current.destroy();
    }
    if (!mainSeries.length || !secondarySeries.length || loading) {
      return;
    }
    const mainOptions = getOptions(
      theme,
      true,
      categories,
      `light-chart-${index}`,
      setShowTooltip,
      setTempTooltipPosition,
      unit,
      index,
      primaryColorsOrder
    );
    const secondaryOptions = getOptions(
      theme,
      false,
      categories,
      `light-chart-${index}`,
      setShowTooltip,
      setTempTooltipPosition,
      unit,
      index,
      [],
      secondaryMax
    );
    mainChartRef.current = new ApexCharts(document.querySelector('#main-chart'), {
      ...mainOptions,
      series: mainSeries
    });
    brushChartRef.current = new ApexCharts(document.querySelector('#sec-chart'), {
      ...secondaryOptions,
      series: secondarySeries
    });

    mainChartRef.current.render();
    brushChartRef.current.render();

    return () => {
      if (mainChartRef.current) {
        mainChartRef.current.destroy();
      }
      if (brushChartRef.current) {
        brushChartRef.current.destroy();
      }
    };
  }, [theme, categories, primaryColorsOrder]);
  useEffect(() => {
    // Remove the tooltip when user is not hovering the chart
    let chart = document.getElementById(`group-chart-wrapper`);
    chart?.addEventListener(
      'mouseleave',
      () => {
        let tooltip = document.getElementById(`${tooltipId}`);
        // Do not remove tooltip if current hover is on the tooltip
        if (!tooltip?.matches(':hover')) {
          setShowTooltip(null);
        }
      },
      true
    );
    return () => {};
  }, [mainSeries]);

  useEffect(() => {
    // Update the position of the tooltip once the user hovers a new column
    let chart = document.getElementById(`grouped-chart-main`);
    let table = document.getElementById(`focus-area-list`);
    if (!chart) return;
    let chartPos = chart.getBoundingClientRect();

    setTooltipPosition({
      ...tempTooltipPosition,
      ...{ top: table.height - window.scrollY + chartPos.height }
    });
    return () => {
      console.log('unmount charrt');
    };
  }, [showTooltip]);

  let colors = [3, 1, 5, 2];

  return (
    <Stack id={'group-chart-wrapper'} sx={{ pt: 2 }}>
      {primaryTitle && (
        <Typography variant={'subtitle1'} bold sx={{ pb: 2 }}>
          {primaryTitle}
        </Typography>
      )}
      {loading && <ApexChart newLoadingAnimation options={{}} type={'bar'} loading data={[]} />}
      <div className={'custom-tooltip-chart'} id={'grouped-chart-main'}>
        <div id="main-chart" />
      </div>
      {!loading && (
        <Typography variant={'subtitle1'} bold sx={{ pb: 2 }}>
          {secondaryTitle}
        </Typography>
      )}
      {!loading && (
        <div className={'custom-tooltip-chart'}>
          <div id="sec-chart" />
        </div>
      )}
      {showTooltip !== null && (
        <CustomTooltip id={tooltipId} position={tooltipPosition}>
          <Stack sx={{ pt: 1, pb: 1, pr: 2, pl: 2 }} spacing={1}>
            <Typography variant={'subtitle1'} bold>
              {moment.utc(categories[showTooltip]).format('DD/MMM/YYYY HH:mm')}
            </Typography>
            {mainSeries?.map(({ name, data }, i) => {
              let index = colors[i];
              if (primaryColorsOrder?.length) {
                index = primaryColorsOrder[i].index;
              }
              return (
                <Stack key={`${i}-values`} direction={'row'} justifyContent={'space-between'}>
                  <LegendValue value={name} color={{ name: 'sixColorsLine', index: index }} />
                  <Typography variant={'body2'}>
                    {parseNumberToSignificant(data[showTooltip][1]) || 0} {unit}
                  </Typography>
                </Stack>
              );
            })}
            <Divider sx={{ borderColor: ({ palette }) => palette.tooltip.divider }} />
            {secondarySeries?.map(({ name, data }, i) => (
              <Stack key={`sec-${i}-values`} direction={'row'} justifyContent={'space-between'}>
                <LegendValue value={name} color={{ name: 'twoColorsContrast', index: i }} />
                <Typography variant={'body2'}>
                  {parseNumberToSignificant(data[showTooltip][1]) || 0}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </CustomTooltip>
      )}
    </Stack>
  );
}

GroupedLineWithCustomTooltip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  primaryColorsOrder: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number.isRequired,
  secondaryMax: PropTypes.number,
  loading: PropTypes.bool,
  unit: PropTypes.string,
  primaryTitle: PropTypes.string,
  secondaryTitle: PropTypes.string,
  mainSeries: PropTypes.arrayOf(PropTypes.object),
  secondarySeries: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string)
};

GroupedLineWithCustomTooltip.defaultProps = {
  unit: false,
  loading: false,
  primaryTitle: null,
  secondaryMax: null,
  primaryColorsOrder: null,
  secondaryTitle: null,
  mainSeries: [],
  secondarySeries: [],
  categories: []
};

export default GroupedLineWithCustomTooltip;

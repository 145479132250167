import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@esgian/esgianui';
import { useTheme } from '@hooks';

function LookupSelect({ label, options, disabled, value, onChange }) {
  const [tempValues, setTempValues] = useState([]);
  const [open, setOpen] = useState(false);
  const { customScrollbar } = useTheme();
  useEffect(() => {
    setTempValues([...value]);
  }, [value]);

  const handleClick = (option, checked) => {
    if (option.value === 'all') {
      if (!checked) {
        setTempValues(options);
      } else {
        setTempValues([]);
      }
    } else {
      if (!checked) {
        setTempValues((prev) => [...prev, option]);
      } else {
        setTempValues((prev) => [...prev.filter((item) => item.value !== option.value)]);
      }
    }
  };

  return (
    <Autocomplete
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      disabled={disabled}
      onClose={() => {
        onChange(tempValues);
        setOpen(false);
      }}
      value={tempValues}
      multiple
      options={[{ name: 'All', value: 'all' }, ...options]}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.name}
      renderOption={(_, option) => {
        let checked = tempValues.some(({ value }) => value === option.value);
        if (option.value === 'all' && tempValues.length === options.length) {
          checked = true;
        }
        return (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleClick(option, checked)}>
            <ListItemIcon>
              <Checkbox size={'small'} color="primary" checked={checked} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={option.name} />
          </MenuItem>
        );
      }}
      renderTags={(value) => {
        let name = '-';
        if (value.length === 1) {
          name = value[0].name;
        }
        if (value.length > 1) {
          name = `${value[0].name} +${value.length - 1}`;
        }
        if (tempValues.length === options.length) {
          name = 'All';
        }
        return (
          <Typography
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            variant={'body2'}>
            {name}
          </Typography>
        );
      }}
      disableClearable
      componentsProps={{
        paper: {
          sx: {
            width: 'fit-content',
            minWidth: '200px'
          }
        }
      }}
      ListboxProps={{ sx: { ...customScrollbar } }}
      renderInput={(params) => (
        <TextField
          onClick={() => setOpen(true)}
          {...params}
          InputProps={{
            ...params.InputProps,
            sx: {
              cursor: 'pointer',
              height: '39px'
            }
          }}
          inputProps={{
            ...params.inputProps,
            value: '',
            sx: {
              height: '0px',
              caretColor: 'transparent',
              cursor: 'pointer'
            }
          }}
          sx={{
            width: '200px'
          }}
          variant="outlined"
          label={<Typography variant={'body2'}>{label}</Typography>}
        />
      )}
    />
  );
}

LookupSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  label: PropTypes.string
};

LookupSelect.defaultProps = {
  disabled: false,
  label: '',
  value: [],
  options: []
};

export default LookupSelect;

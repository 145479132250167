import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Stack, TextField, Typography } from '@esgian/esgianui';

function SettingInput({ title, value, onChange, disabled, loading, min, max }) {
  const [error, setError] = useState(null);

  const handleChange = useCallback(({ target }) => {
    if (/^\d+$/.test(target.value)) {
      let value = parseInt(target.value);
      let error = false;
      if (min !== null && max !== null) {
        if (value < min || value > max) {
          setError(`Value must be between: ${min} - ${max}`);
          error = true;
        } else {
          setError(null);
        }
      } else if (min !== null) {
        if (value < min) {
          setError(`Min: ${min}`);
          error = true;
        } else {
          setError(null);
        }
      } else if (max !== null) {
        if (value < min) {
          setError(`Max: ${min}`);
          error = true;
        } else {
          setError(null);
        }
      }
      onChange(value, error);
    } else if (target.value === '') {
      setError('Required');
      onChange(null, true);
    }
  }, []);

  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
        <Typography
          bold={!!error}
          sx={{ color: ({ palette }) => (error ? palette.error.main : palette.text.primary) }}
          variant={'body2'}>
          &#8226; {title}{' '}
        </Typography>
        {loading ? (
          <CircularProgress size={15} />
        ) : (
          <TextField
            error={error}
            inputProps={{ sx: { fontSize: '14px' } }}
            value={value || ''}
            onChange={handleChange}
            disabled={disabled || loading}
            className={'settings-number-input'}
            sx={{ textAlignLast: 'end', width: '50px' }}
            size={'small'}
            variant={'standard'}
          />
        )}
      </Stack>
      {error && (
        <Typography
          alignSelf={'end'}
          variant={'caption'}
          sx={{ color: ({ palette }) => palette.error.main }}>
          {error}
        </Typography>
      )}
    </Stack>
  );
}

SettingInput.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

SettingInput.defaultProps = {
  title: '',
  value: null,
  min: null,
  max: null,
  disabled: false,
  loading: false
};

export default SettingInput;

import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack } from '@esgian/esgianui';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { timeSeries1, timeSeries2 } from '@pages/mock';
import moment from 'moment/moment';
function generateDataPoints(startTime, numPoints) {
  const dataPoints = [];
  const oneHour = 60 * 60 * 1000; // One hour in milliseconds

  for (let i = 0; i < numPoints; i++) {
    dataPoints.push({
      x: startTime + i * oneHour,
      y: Math.floor(Math.random() * 201) // Random value between 0 and 200
    });
  }

  return dataPoints;
}

const startTime = moment.utc().startOf('hour').valueOf(); // Start time 100 hours ago

function TestPage(props) {
  const fistChartRef = useRef(null);
  const secChartRef = useRef(null);

  const series1 = useMemo(() => {
    return [
      {
        label: 'first',
        data: generateDataPoints(startTime, 1000)
      },
      {
        label: 'second',
        fill: 'origin',
        backgroundColor: 'rgba(255,0,0,0.56)',
        data: generateDataPoints(startTime, 1000)
      }
    ];
  }, []);
  const series2 = useMemo(() => {
    return [
      {
        label: 'third',
        data: generateDataPoints(startTime, 1000)
      },
      {
        label: 'forth',
        data: generateDataPoints(startTime, 1000)
      }
    ];
  }, []);

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <Grid container>
        <Grid item xs={12}>
          <Stack>
            <CanvasTimeSeriesChart
              syncRefs={[secChartRef]}
              chartRef={fistChartRef}
              series={series1}
              id={'first-chart'}
            />
            <CanvasTimeSeriesChart
              chartRef={secChartRef}
              syncRefs={[fistChartRef]}
              series={series2}
              id={'sec-chart'}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

TestPage.propTypes = {};

TestPage.defaultProps = {};

export default TestPage;

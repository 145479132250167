import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  CloseIcon,
  EsgianTheme,
  getEsgianTheme,
  Link,
  Stack,
  Typography
} from '@esgian/esgianui';
import { APP_NAME, MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import mapboxgl from 'mapbox-gl';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'react-redux';
import { getLookupRigs, getThemeMode } from '@store/features';

const putMarkerOnMap = (map, themeMode) => {
  const {
    palette: {
      mode,
      primary: { light, dark },
      secondary: { light: secondaryLight, dark: secondaryDark },
      neutral: { primary, secondary },
      tooltip: { background: tooltipBackground, contrastText }
    }
  } = getEsgianTheme(themeMode, 'gpr');

  let nonTrackedColor = themeMode ? secondary : primary;
  let trackedColor = themeMode ? dark : light;

  map.addLayer({
    id: 'rig-locations-layer',
    type: 'circle',
    source: 'rig-locations',
    paint: {
      'circle-radius': 8,
      'circle-color': [
        'match',
        ['get', 'isTracked'],
        'true',
        trackedColor,
        'false',
        nonTrackedColor,
        nonTrackedColor
      ],
      'circle-stroke-color': trackedColor,
      'circle-stroke-width': 2
    }
  });
  let popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
  });

  map.on('mouseenter', 'rig-locations-layer', (e) => {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const location = e.features[0].properties.location;
    const rigName = e.features[0].properties.rigName;
    const status = e.features[0].properties.status;
    const rigId = e.features[0].properties.rigId;
    const isTracked = e.features[0].properties.isTracked === 'true';

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    let tooltipHtml = (
      <div
        id={'rig-tooltip'}
        style={{ background: tooltipBackground, minWidth: '230px', borderRadius: '4px' }}>
        <EsgianTheme app={APP_NAME} mode={mode}>
          <div style={{ display: 'flex', placeContent: 'flex-end' }}>
            <div
              style={{
                padding: '8px',
                alignSelf: 'flex-end',
                cursor: 'pointer',
                color: contrastText
              }}
              id={'close-button'}>
              <div style={{ width: '16px' }}>
                <CloseIcon style={{ fill: contrastText }} fontSize={'small'} />
              </div>
            </div>
          </div>
          <div style={{ padding: '0px 16px 16px 16px' }}>
            <Stack spacing={2}>
              <Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography style={{ color: contrastText }} variant={'body2'}>
                    Rig name:
                  </Typography>
                  <Typography variant={'body2'} style={{ color: contrastText }}>
                    {rigName}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'body2'} style={{ color: contrastText }}>
                    Location:
                  </Typography>
                  <Typography variant={'body2'} style={{ color: contrastText }}>
                    {location || '-'}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'body2'} style={{ color: contrastText }}>
                    Status:
                  </Typography>
                  <Typography variant={'body2'} style={{ color: contrastText }}>
                    {status || '-'}
                  </Typography>
                </Stack>
              </Stack>

              {isTracked && (
                <>
                  <Link
                    href={`/gpr-live/rig?rig=${rigId}`}
                    underline={'none'}
                    size={'small'}
                    style={{
                      transition: 'all 0.1s ease-in',
                      padding: '8px 16px 8px 16px',
                      cursor: 'pointer',
                      alignSelf: 'center',
                      outline: 'none !important',
                      borderRadius: '20px',
                      textDecoration: 'none',
                      border: `1px solid ${themeMode ? secondaryDark : secondaryLight}`,
                      color: themeMode ? secondaryDark : secondaryLight
                    }}>
                    SEE RIG PAGE
                  </Link>
                </>
              )}
            </Stack>
          </div>
        </EsgianTheme>
      </div>
    );

    popup.setLngLat(coordinates).setHTML(renderToString(tooltipHtml)).addTo(map);
    document.getElementById('close-button').addEventListener('click', () => popup.remove());
  });

  // map.on('mouseleave', 'rig-locations-layer', () => {
  //   map.getCanvas().style.cursor = '';
  //   let tooltip = document.getElementsByClassName(`animated-popup-map-tooltip`)[0];
  //   if (!tooltip?.matches(':hover')) {
  //     // popup.remove();
  //   }
  // });
};

const createMarkers = (map, rigsLookup, themeMode) => {
  if (map.getLayer('rig-locations-layer')) {
    map.removeLayer('rig-locations-layer');
  }
  if (map.getSource('rig-locations')) {
    map.removeSource('rig-locations');
  }

  let features = rigsLookup?.map(
    ({ location, status, rigId, rigName, latitude, longitude, isTracked }) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude]
        },
        properties: {
          location: location,
          rigName: rigName,
          rigId: rigId,
          status: status,
          isTracked: `${isTracked}`
        }
      };
    }
  );

  map.addSource('rig-locations', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features
    }
  });

  putMarkerOnMap(map, themeMode);
};

function DashboardOverviewMap() {
  const mapContainer = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const map = useRef(null);
  const themeMode = useSelector(getThemeMode);
  const lookupRigs = useSelector(getLookupRigs);

  useEffect(() => {
    setMapLoaded(false);
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: themeMode ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE,
        projection: { name: 'globe' },
        height: '50vh',
        zoom: 3
      });
    }
    map.current.on('load', () => {
      setMapLoaded(true);
    });
    return () => {
      map.current = null;
    };
  }, [themeMode]);

  useEffect(() => {
    if (!lookupRigs?.length || !mapLoaded) return;
    let firstTracked = lookupRigs.find(({ isTracked }) => isTracked);
    const { longitude = 0, latitude = 0 } = firstTracked || {};
    map.current.flyTo({ center: [longitude, latitude], zoom: 2.5, duration: 2000 });
    createMarkers(map.current, lookupRigs, themeMode);
  }, [lookupRigs, mapLoaded]);

  return (
    <div
      ref={mapContainer}
      style={{
        height: '75vh'
      }}
      className="dashboard-map">
      {!lookupRigs?.length && (
        <div
          style={{
            height: '100%',
            width: '100%',
            zIndex: 2,
            position: 'absolute'
          }}>
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </div>
      )}
    </div>
  );
}

DashboardOverviewMap.propTypes = {
  loading: PropTypes.bool
};

DashboardOverviewMap.defaultProps = {
  loading: false
};

export default DashboardOverviewMap;

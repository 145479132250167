export const getRgbaColor = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
export const darkenHexColor = (hex, percent) => {
  // Remove the "#" if present
  hex = hex.replace(/^#/, '');

  // Convert 3-digit hex to 6-digit hex
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((h) => h + h)
      .join('');
  }

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the darker shade
  r = Math.max(0, Math.min(255, Math.floor(r * (1 - percent / 100))));
  g = Math.max(0, Math.min(255, Math.floor(g * (1 - percent / 100))));
  b = Math.max(0, Math.min(255, Math.floor(b * (1 - percent / 100))));

  // Convert back to a hex string
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
};
export const lightenHexColor = (hex, percent) => {
  // Remove the "#" if present
  hex = hex.replace(/^#/, '');

  // Convert 3-digit hex to 6-digit hex
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((h) => h + h)
      .join('');
  }

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the lighter shade
  r = Math.max(0, Math.min(255, Math.floor(r + (255 - r) * (percent / 100))));
  g = Math.max(0, Math.min(255, Math.floor(g + (255 - g) * (percent / 100))));
  b = Math.max(0, Math.min(255, Math.floor(b + (255 - b) * (percent / 100))));

  // Convert back to a hex string
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
};

// Convert hex color to rgba format
export const hexToRgba = (hex, opacity) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const adjustColorOpacity = (color, opacity) => {
  if (!color) return undefined;
  // Check if the color is in hex format
  if (color.startsWith('#')) {
    return hexToRgba(color, opacity);
  }

  // Check if the color is already in rgba format
  if (color.startsWith('rgba')) {
    return color.replace(/rgba\((\d+), (\d+), (\d+), [^)]+\)/, `rgba($1, $2, $3, ${opacity})`);
  }

  // If the color is in rgb format, convert it to rgba
  if (color.startsWith('rgb')) {
    return color.replace(/rgb\((\d+), (\d+), (\d+)\)/, `rgba($1, $2, $3, ${opacity})`);
  }

  return color; // Return original color if format is unrecognized
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select, Stack, Typography } from '@esgian/esgianui';

function ViewBySelect({ disabled, handleChange, selectedView }) {
  return (
    <Stack spacing={1} direction={'row'} alignItems={'center'}>
      <Typography variant={'body2'}>View by</Typography>
      <FormControl disabled={disabled} sx={{ width: 110 }} size={'small'}>
        <Select
          variant={'outlined'}
          inputProps={{ sx: { pl: 1, pr: 1, pt: 0.5, pb: 0.5 } }}
          value={selectedView || ''}
          onChange={({ target }) => handleChange(target.value)}>
          <MenuItem value={'hour'}>Hour</MenuItem>
          <MenuItem value={'min'}>Min</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}

ViewBySelect.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  selectedView: PropTypes.oneOf(['hour', 'min'])
};

ViewBySelect.defaultProps = {
  disabled: false,
  selectedView: 'hour'
};

export default ViewBySelect;

import React from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from '@components/Charts/BarChartWithCustomTooltip/CustomTooltip/CustomTooltip';
import { Box, Button, Divider, Stack, Typography } from '@esgian/esgianui';
import { parseNumberToSignificant } from '@helpers';

function OperationsPeriodicOverviewTooltip({
  unitTitle,
  legendColors,
  handleLogClick,
  position,
  column,
  date,
  series
}) {
  const sum = parseNumberToSignificant(
    parseFloat(series[0].data[column]) + parseFloat(series[1].data[column])
  );
  return (
    <CustomTooltip id={'opo-custom-tooltip'} position={position}>
      <Stack sx={{ pt: 1, pb: 1, pr: 2, pl: 2 }} spacing={1}>
        <Typography variant={'subtitle2'} bold>
          {date}
        </Typography>
        <Stack>
          <Stack spacing={1} justifyContent={'space-between'} direction={'row'}>
            <Typography variant={'body2'}>Sum: </Typography>
            <Typography variant={'body2'}>{`${sum} ${unitTitle}`}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ borderColor: ({ palette }) => palette.tooltip.contrastText }} />
        <Stack>
          <Stack spacing={1} justifyContent={'space-between'} direction={'row'}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <div
                style={{
                  marginBottom: '2px',
                  height: '10px',
                  width: '10px',
                  background: legendColors[0]
                }}
              />
              <Typography variant={'body2'}>Hotel and utilities: </Typography>
            </Stack>
            <Typography variant={'body2'}>{`${parseNumberToSignificant(
              series[0].data[column]
            )} ${unitTitle}`}</Typography>
          </Stack>
          <Stack spacing={1} justifyContent={'space-between'} direction={'row'}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <div
                style={{
                  marginBottom: '2px',
                  height: '10px',
                  width: '10px',
                  background: legendColors[1]
                }}
              />
              <Typography variant={'body2'}>Drilling: </Typography>
            </Stack>
            <Typography variant={'body2'}>{`${parseNumberToSignificant(
              series[1].data[column]
            )} ${unitTitle}`}</Typography>
          </Stack>
        </Stack>
        <Box sx={{ p: 1, alignSelf: 'center' }}>
          <Button
            onClick={() => handleLogClick(column)}
            sx={{
              color: ({ palette }) => palette.tooltip.contrastText,
              borderColor: ({ palette }) => palette.tooltip.contrastText
            }}
            color={'secondary'}
            size={'medium'}
            variant={'outlined'}>
            See log
          </Button>
        </Box>
      </Stack>
    </CustomTooltip>
  );
}

OperationsPeriodicOverviewTooltip.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number
  }),
  handleLogClick: PropTypes.func.isRequired,
  column: PropTypes.number,
  unitTitle: PropTypes.string,
  date: PropTypes.string,
  legendColors: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.object)
};

OperationsPeriodicOverviewTooltip.defaultProps = {
  position: {
    top: 0,
    left: 0
  },
  column: null,
  date: '',
  unitTitle: '',
  legendColors: [],
  series: []
};

export default OperationsPeriodicOverviewTooltip;

import React from 'react';
import { Grid } from '@esgian/esgianui';
import { EnergyMonitor } from '@components';
import { FocusAreasHeatMap } from '@components/SubSections/Analytics';
import { FocusAreasTable } from '@components/Tables';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';

function AnalyticsSection() {
  const { rigAnalyticsQuery } = useMainPageQueries();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <EnergyMonitor
          analyticsData={rigAnalyticsQuery.data}
          loading={rigAnalyticsQuery.isFetching}
        />
      </Grid>
      <Grid item xs={6}>
        <FocusAreasHeatMap
          analyticsData={rigAnalyticsQuery.data}
          loading={rigAnalyticsQuery.isFetching}
        />
      </Grid>
      <Grid item xs={12}>
        <FocusAreasTable
          analyticsData={rigAnalyticsQuery.data}
          loading={rigAnalyticsQuery.isFetching}
        />
      </Grid>
    </Grid>
  );
}

AnalyticsSection.propTypes = {};

AnalyticsSection.defaultProps = {};

export default AnalyticsSection;

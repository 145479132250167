import React from 'react';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { DATE_FORMAT, TIME_ZONE } from '@constants';
import { useTheme } from '@hooks';
import PropTypes from 'prop-types';
import { setTimeZone, setCustomUTC } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';

const WellSelect = ({
  onSave,
  handleClose,
  isLoading,
  wellsData,
  selectedWell,
  setSelectedWell,
  allowTimeZoneChange
}) => {
  const dispatch = useDispatch();
  const { customScrollbar } = useTheme();
  const { rigUTC, timeZone, customUTC } = useSelector(getMainPageSlice);

  const handleSelect = ({ target }) => {
    const index = target.value;
    const selected = wellsData[index];
    setSelectedWell(selected ? { ...selected, index } : null);
    if (selected) {
      if (allowTimeZoneChange) {
        const dateParts = selected.starttime.split(/([+-]\d{2}:\d{2})$/);
        const offset = dateParts[1];
        const possibleTimezones = moment.tz.names().filter((tz) => {
          return moment.tz(selected.starttime, tz).format('Z') === offset;
        });
        const currentTimeZone = timeZone === TIME_ZONE ? rigUTC : customUTC?.value;
        const currentUtcOffset = moment.tz(currentTimeZone).utcOffset() / 60;
        const selectedUtcOffset = moment.tz(possibleTimezones[0]).utcOffset() / 60;
        const timezoneName = possibleTimezones.length
          ? possibleTimezones[0]
          : 'No matching timezone found';

        if (currentUtcOffset !== selectedUtcOffset) {
          toast.warning(`Timezone changed to UTC ${offset}`);
        }
        dispatch(setTimeZone('CustomTimeZone'));
        dispatch(setCustomUTC({ value: timezoneName, label: `${timezoneName} (UTC${offset})` }));
      }

      onSave({
        startDate: selected.starttime,
        endDate: selected.endtime,
        type: 'well',
        name: `${selected?.wellname}: ${moment(selected.starttime, DATE_FORMAT).format(
          DATE_FORMAT
        )} - ${moment(selected.endtime, DATE_FORMAT).format(DATE_FORMAT)}`
      });
      handleClose();
    }
  };

  return (
    <Stack>
      <FormControl sx={{ minWidth: 240 }} size={'small'}>
        <TextField
          disabled={isLoading}
          SelectProps={{
            MenuProps: {
              slotProps: { paper: { sx: { ...customScrollbar } } },
              sx: { ...customScrollbar, maxHeight: '50vh' }
            }
          }}
          size={'medium'}
          select
          label={isLoading ? <CircularProgress size={25} /> : 'Well'}
          value={!isLoading ? selectedWell?.index ?? 'none' : ''}
          variant={'outlined'}
          onChange={handleSelect}>
          <MenuItem value={'none'}>{'Select'}</MenuItem>
          {wellsData.map(({ wellname, starttime, endtime }, i) => (
            <MenuItem key={`rig-well-${i}`} value={i}>
              <Stack>
                <Typography variant={'body2'}> {wellname}</Typography>
                <Typography color={'text.secondary'} variant={'caption'}>
                  {`${moment.parseZone(starttime).format('DD/MMM/YYYY')} - ${moment
                    .parseZone(endtime)
                    .format('DD/MMM/YYYY')}`}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Stack>
  );
};

WellSelect.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedWell: PropTypes.object,
  setSelectedWell: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  wellsData: PropTypes.array,
  allowTimeZoneChange: PropTypes.bool,
  currentRig: PropTypes.object
};

export default WellSelect;

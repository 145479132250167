import React from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@esgian/esgianui';
import { DISPLAY_UNIT } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayUnit, setDisplayUnit } from '@store/features';

function UnitDisplaySelect() {
  const displayUnit = useSelector(getDisplayUnit);
  const dispatch = useDispatch();

  return (
    <RadioGroup
      row
      value={displayUnit}
      onChange={({ target }) => {
        dispatch(setDisplayUnit(target.value));
      }}>
      <FormControlLabel
        value={DISPLAY_UNIT.ENERGY}
        control={<Radio size={'small'} />}
        label={<Typography variant={'body2'}>Energy</Typography>}
      />
      <FormControlLabel
        value={DISPLAY_UNIT.FUEL}
        control={<Radio size={'small'} />}
        label={<Typography variant={'body2'}>Fuel</Typography>}
      />
      <FormControlLabel
        value={DISPLAY_UNIT.CO2}
        control={<Radio size={'small'} />}
        label={
          <Typography variant={'body2'} sx={{ pt: '4px' }}>
            CO<sub style={{ fontSize: '10px' }}>2</sub>e
          </Typography>
        }
      />
      <FormControlLabel
        value={DISPLAY_UNIT.NOX}
        control={<Radio size={'small'} />}
        label={
          <Typography variant={'body2'} sx={{ pt: '4px' }}>
            NO<sub>x</sub>
          </Typography>
        }
      />
    </RadioGroup>
  );
}

UnitDisplaySelect.propTypes = {};

UnitDisplaySelect.defaultProps = {};

export default UnitDisplaySelect;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography } from '@esgian/esgianui';
import moment from 'moment-timezone';
import { LegendValue } from '@components';
import CustomTooltip from '@components/Charts/BarChartWithCustomTooltip/CustomTooltip/CustomTooltip';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { useTheme } from '@hooks';
import ApexCharts from 'apexcharts';

const getOptions = (
  series,
  height,
  theme,
  isPrimary,
  categories,
  group,
  setShowTooltip,
  setPositions,
  handleColumnClick,
  maxYAxis
) => {
  const {
    palette: {
      mode,
      charts: { background, primaryChartColor, secondaryChartColor }
    }
  } = theme;
  return {
    series: series,
    chart: {
      animations: {
        enabled: false
      },
      height: height,
      id: isPrimary ? '-load-main' : '-load-secondary',
      type: 'bar',
      parentHeightOffset: 0,
      background: background,
      group: group,
      toolbar: {
        show: false
      },
      events: {
        click: (event, chartContext, config) => {
          if (config.dataPointIndex >= 0) {
            handleColumnClick(config.dataPointIndex);
          }
        },
        mouseMove: function (event) {
          let leftP = event.pageX;
          setPositions((prevPos) => ({ ...prevPos, left: leftP - 320 }));
        }
      }
    },
    colors: isPrimary ? [primaryChartColor] : [secondaryChartColor],
    theme: {
      mode: mode
    },
    fill: {
      opacity: 1
    },

    dataLabels: {
      enabled: false
    },

    tooltip: {
      intersect: false,
      custom: function ({ dataPointIndex }) {
        if (isPrimary) {
          setShowTooltip(dataPointIndex);
          return '<div id="' + 'id' + '-tooltip" style="visibility: hidden" />';
        } else {
          return '';
        }
      }
    },
    yaxis: {
      max: (max) => (isPrimary ? 100 : maxYAxis || parseInt(max) + 1),
      tickAmount: 5,
      decimalsInFloat: 0,
      forceNiceScale: !isPrimary
    },
    title: isPrimary
      ? {
          text: '%',
          align: 'left',
          offsetX: 15,
          rotate: -90,
          offsetY: 20,
          style: {
            fontWeight: 100,
            fontSize: '10px'
          }
        }
      : {},
    xaxis: {
      axisTicks: {
        show: false
      },
      categories: categories?.map((val) => moment(val).format('DD MMM')),
      labels: {
        style: {
          fontSize: '10px'
        },
        show: !isPrimary,
        rotate: -45,
        rotateAlways: true,
        hideOverlappingLabels: false
      }
    }
  };
};

function GroupedBarsWithCustomTooltip({
  setSelectedDate,
  tooltipId,
  mainSeries,
  secondarySeries,
  categories,
  secondaryMax
}) {
  const [logInfo, setLogInfo] = useState(null);
  const [showTooltip, setShowTooltip] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const [tempTooltipPosition, setTempTooltipPosition] = useState({ left: 0, top: 0 });
  const { theme } = useTheme();
  const mainChartRef = useRef(null);
  const brushChartRef = useRef(null);
  const handleColumnClick = useCallback(
    (val) => {
      setSelectedDate({
        date: categories?.[val],
        index: val,
        isLast: val === categories?.length - 1
      });
    },
    [categories]
  );

  /**
   * Workaround due to issues with group not working on option change.
   * See: https://github.com/apexcharts/apexcharts.js/issues/623
   */
  useEffect(() => {
    if (mainChartRef.current) {
      mainChartRef.current.destroy();
    }
    if (brushChartRef.current) {
      brushChartRef.current.destroy();
    }
    if (!mainSeries.length || !secondarySeries.length) {
      return;
    }

    const mainOptions = getOptions(
      mainSeries,
      317,
      theme,
      true,
      categories,
      'timegroup',
      setShowTooltip,
      setTempTooltipPosition,
      handleColumnClick
    );
    const secondaryOptions = getOptions(
      secondarySeries,
      180,
      theme,
      false,
      categories,
      'timegroup',
      setShowTooltip,
      setTempTooltipPosition,
      handleColumnClick,
      secondaryMax
    );

    mainChartRef.current = new ApexCharts(document.querySelector('#main'), mainOptions);
    brushChartRef.current = new ApexCharts(document.querySelector('#brush'), secondaryOptions);

    mainChartRef.current.render();
    brushChartRef.current.render();

    return () => {
      if (mainChartRef.current) {
        mainChartRef.current.destroy();
      }
      if (brushChartRef.current) {
        brushChartRef.current.destroy();
      }
    };
  }, [mainSeries, secondarySeries, theme, categories]);

  useEffect(() => {
    // Remove the tooltip when user is not hovering the chart
    let chart = document.getElementById(`group-chart-wrapper`);
    chart?.addEventListener('mouseleave', () => {
      let tooltip = document.getElementById(`${tooltipId}`);
      // Do not remove tooltip if current hover is on the tooltip
      if (!tooltip?.matches(':hover')) {
        setShowTooltip(null);
      }
    });
  }, [mainSeries]);

  useEffect(() => {
    // Update the position of the tooltip once the user hovers a new column
    let chart = document.getElementById(`grouped-chart-main`);
    if (!chart) return;
    let pos = chart.getBoundingClientRect();
    setTooltipPosition({ ...tempTooltipPosition, ...{ top: pos.top + window.scrollY } });
  }, [showTooltip]);

  return (
    <Stack id={'group-chart-wrapper'} sx={{ pt: 2 }}>
      <LegendValue value={'Load %'} color={{ name: 'primaryChartColor', isSingle: true }} />
      <div className={'custom-tooltip-chart'} id={'grouped-chart-main'}>
        <div id={'main'} />
      </div>
      <LegendValue
        value={'Engine online'}
        color={{ name: 'secondaryChartColor', isSingle: true }}
      />
      <div style={{ paddingLeft: '13px' }} className={'custom-tooltip-chart'}>
        <div id={'brush'} />
      </div>
      {showTooltip !== null && (
        <CustomTooltip id={tooltipId} position={tooltipPosition}>
          <Stack sx={{ pt: 1, pb: 1, pr: 2, pl: 2 }} spacing={1}>
            <Typography variant={'subtitle1'} bold>
              {moment(categories[showTooltip]).format('DD/MMM/YYYY')}
            </Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <LegendValue value={'Load %'} color={{ name: 'primaryChartColor', isSingle: true }} />
              <Typography variant={'body2'}>{mainSeries[0].data[showTooltip] || 0}%</Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <LegendValue
                value={'Engine online'}
                color={{ name: 'secondaryChartColor', isSingle: true }}
              />
              <Typography variant={'body2'}>{secondarySeries[0].data[showTooltip]}</Typography>
            </Stack>
            <Box sx={{ p: 1, alignSelf: 'center' }}>
              <Button
                onClick={() => {
                  setLogInfo({ startDate: categories[showTooltip], unit: 'day' });
                }}
                sx={{
                  color: ({ palette }) => palette.tooltip.contrastText,
                  borderColor: ({ palette }) => palette.tooltip.contrastText
                }}
                color={'secondary'}
                size={'medium'}
                variant={'outlined'}>
                See log
              </Button>
            </Box>
          </Stack>
        </CustomTooltip>
      )}
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </Stack>
  );
}

GroupedBarsWithCustomTooltip.propTypes = {
  setSelectedDate: PropTypes.func.isRequired,
  tooltipId: PropTypes.string.isRequired,
  mainSeries: PropTypes.arrayOf(PropTypes.object),
  secondarySeries: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string),
  secondaryMax: PropTypes.number
};

GroupedBarsWithCustomTooltip.defaultProps = {
  mainSeries: [],
  secondarySeries: [],
  secondaryMax: null,
  categories: []
};

export default GroupedBarsWithCustomTooltip;

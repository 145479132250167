import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  MenuIcon,
  Stack,
  Typography,
  VisibilityOffOutlinedIcon,
  VisibilityOutlinedIcon
} from '@esgian/esgianui';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function GraphDisplaySettingList({ sections, onChange }) {
  const updateVisibility = (graphName) => {
    let temp = [...sections];
    let index = sections?.findIndex(({ graphName: gName }) => gName === graphName);
    temp[index] = { ...temp[index], visible: !temp[index].visible };
    onChange(temp);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(sections, source.index, destination.index);

    onChange(newItems);
  };

  return (
    <Stack spacing={1}>
      <Typography variant={'subtitle1'} bold>
        Graph Display
      </Typography>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'caption'} color={'text.disabled'}>
          GRAPH SEQUENCE
        </Typography>
        <Typography variant={'caption'} color={'text.disabled'}>
          VISIBILITY
        </Typography>
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              style={{ flexDirection: 'column', display: 'flex', gap: '8px' }}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {sections.map(({ graphName, rank, visible }, index) => (
                <Draggable
                  key={`item-${graphName.replace(/\s+/g, '-')}`}
                  draggableId={`${rank}`}
                  index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <span style={{ display: 'none' }}>{provided.placeholder}</span>
                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                          <MenuIcon fontSize={'16px'} />
                          <Typography variant={'body2'}>{graphName}</Typography>
                        </Stack>
                        <IconButton onClick={() => updateVisibility(graphName)} size={'small'}>
                          {visible ? (
                            <VisibilityOutlinedIcon fontSize={'16px'} />
                          ) : (
                            <VisibilityOffOutlinedIcon fontSize={'16px'} />
                          )}
                        </IconButton>
                      </Stack>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
}

GraphDisplaySettingList.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired
};

GraphDisplaySettingList.defaultProps = {
  sections: []
};

export default GraphDisplaySettingList;

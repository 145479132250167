import { BaseApiRequest } from '@api/BaseApi';
import { getToken } from '@store/helpers';
import { getOffset } from '@api/helpers';
import { APP_NAME } from '@constants';

export const getSavedTimePeriod = () => {
  const sessionState = JSON.parse(sessionStorage.getItem(APP_NAME));
  return BaseApiRequest(null, `/SavedTimePeriod/Get`, 'gpr', 'POST', {
    userId: sessionState?.auth?.user?.id || null,
    token: getToken()
  });
};

export const createSavedTimePeriod = (data) => {
  return BaseApiRequest(null, `/SavedTimePeriod/Create`, 'gpr', 'POST', {
    ...data,
    userTimezone: getOffset(data.startDate),
    token: getToken()
  });
};

export const updateSavedTimePeriod = (data) => {
  return BaseApiRequest(null, `/SavedTimePeriod/Update`, 'gpr', 'PUT', {
    ...data,
    userTimezone: getOffset(data.startDate),
    token: getToken()
  });
};

export const deleteSavedTimePeriod = (id) => {
  return BaseApiRequest(null, `/SavedTimePeriod/Delete`, 'gpr', 'DELETE', {
    id,
    token: getToken()
  });
};

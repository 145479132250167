import { BaseApiRequest } from '@api/BaseApi';
import { getToken } from '@store/helpers';

export const getRigSettings = (signal, selectedRig) => {
  return BaseApiRequest(signal, `/DashboardParameters/Get`, 'gpr', 'POST', {
    token: getToken(),
    rigId: selectedRig.rigId
  });
};

export const updateRigSetting = (signal, selectedRig, fields) => {
  return BaseApiRequest(signal, `/DashboardParameters/Update`, 'gpr', 'PUT', {
    ...fields,
    token: getToken(),
    rigId: selectedRig.rigId
  });
};

export const getRigFuelPrice = (signal, { selectedRig }) => {
  return BaseApiRequest(signal, `/FuelPrice/Get`, 'gpr', 'POST', {
    token: getToken(),
    rigId: selectedRig.rigId
  });
};

export const updateRigFuelPrice = (signal, selectedRig, fields) => {
  return BaseApiRequest(signal, `/FuelPrice/Update`, 'gpr', 'PUT', {
    ...fields,
    token: getToken(),
    rigId: selectedRig.rigId
  });
};
export const createRigFuelPrice = (signal, selectedRig, fields) => {
  return BaseApiRequest(signal, `/FuelPrice/Create`, 'gpr', 'POST', {
    ...fields,
    token: getToken(),
    rigId: selectedRig.rigId
  });
};

export const deleteFuelPrice = (signal, selectedRig, fields) => {
  return BaseApiRequest(signal, `/FuelPrice/Delete`, 'gpr', 'DELETE', {
    ...fields,
    token: getToken(),
    rigId: selectedRig.rigId
  });
};

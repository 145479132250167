import React, { useCallback, useMemo } from 'react';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import DrillingActivitiesKpis from '@components/Sections/BaselineComparisonSection/SubSections/DrillingActivities/DrillingActivitiesKpis';
import { VIEW_BY_TYPE } from '@constants';
import {
  getBaselineComparisonSlice,
  setDrillingActivitiesFilters
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { BaselineComparisonChart } from '@components/Charts';
import DataTypeSelect from '../../../../Inputs/DataTypeSelect';
import LookupSelect from '@components/Inputs/LookupSelect';
import { LOOKUP_DRILLING_ACTIVITY } from '../../../../../lookups';
import { useBaselineComp } from '@hooks/useBaselineComp';
import HourDaySelect from '../../../../Inputs/HourDaySelect';
import { BaselineChartTypeSelect } from '@components/Inputs';
import { useBaselineQueries } from '@hooks';

function DrillingActivitiesComparison() {
  const displayUnit = useSelector(getDisplayUnit);
  const dispatch = useDispatch();
  const { getDrillingSeries } = useBaselineComp();
  const { baselineDrillingQueries } = useBaselineQueries();

  const {
    drillingActivities: { chartType, viewBy, dataType, activityType }
  } = useSelector(getBaselineComparisonSlice);

  const handleChange = useCallback((val) => {
    dispatch(setDrillingActivitiesFilters(val));
  }, []);

  const mainData = baselineDrillingQueries[0].data;
  const baselineData = baselineDrillingQueries[1].data;

  const {
    mainSeries = [],
    baseSeries = [],
    categories = [],
    unit,
    max = 0
  } = useMemo(() => {
    if (!mainData || !baselineData) return {};
    return getDrillingSeries(mainData, baselineData);
  }, [displayUnit, mainData, baselineData, dataType, activityType, viewBy]);

  const loading = baselineDrillingQueries?.some((item) => item.isFetching);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant={'h6'}>Drilling activities comparison</Typography>
          {/*<PeriodRangeButtons*/}
          {/*  onClick={({ index }) => handleChange({ displayPeriod: index })}*/}
          {/*  value={{ index: displayPeriod }}*/}
          {/*  options={[DISPLAY_PERIOD.LAST_WEEK, DISPLAY_PERIOD.LAST_MONTH, DISPLAY_PERIOD.ALL_TIME]}*/}
          {/*/>*/}
        </Stack>
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparing to the Baseline
        </Typography>
        <DrillingActivitiesKpis loading={loading} baselineData={baselineData} mainData={mainData} />
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparison over time
        </Typography>
        <Stack>
          <BaselineComparisonChart
            viewBy={viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour'}
            dataType={dataType}
            chartType={chartType}
            chartActions={[
              <LookupSelect
                options={LOOKUP_DRILLING_ACTIVITY}
                label={'Drilling Activities'}
                key={'drilling-select'}
                value={activityType}
                onChange={(val) => {
                  handleChange({ activityType: val });
                }}
              />,
              <BaselineChartTypeSelect
                key={'baseline-chart-type-select'}
                value={chartType}
                handleChange={(val) => {
                  handleChange({ chartType: val });
                }}
              />,
              <DataTypeSelect
                key={'data-type-select'}
                value={dataType}
                onChange={(val) => {
                  handleChange({ dataType: val });
                }}
              />,
              <HourDaySelect
                key={'view-by-select'}
                value={viewBy}
                handleChange={(val) => {
                  handleChange({ viewBy: val });
                }}
              />
            ]}
            yAxisTitle={unit}
            mainMax={max}
            loading={loading}
            mainSeries={mainSeries}
            baseSeries={baseSeries}
            categories={categories}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

DrillingActivitiesComparison.propTypes = {};

DrillingActivitiesComparison.defaultProps = {};

export default DrillingActivitiesComparison;

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  MonetizationOnOutlinedIcon,
  Paper,
  RestoreIcon,
  Skeleton,
  Stack,
  TrendingDownIcon,
  TrendingUpIcon,
  Typography,
  Box
} from '@esgian/esgianui';
import PeriodRangeButtons from '@components/Inputs/PeriodRangeButtons/PeriodRangeButtons';
import { fetchOverviewKeyFiguresDataV2 } from '@api/Rig';
import moment from 'moment/moment';
import { API_ROLLUP, DISPLAY_PERIOD, DISPLAY_UNIT, UTC_TIMESTAMP } from '@constants';
import InsightDisplayValue from '@components/Sections/Dashboard/Insights/InsightDisplayValue';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { useDisplayUnit } from '@hooks';
import {
  setInsightsRange,
  getInsightsRange
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getDollarAmountReadable } from '@helpers';
import { useQueries } from '@tanstack/react-query';

const RedArrowSVG = () => <TrendingUpIcon color={'error'} fontSize={'small'} />;

const GreenArrowSVG = () => <TrendingDownIcon color={'success'} fontSize={'small'} />;

const checkTruthy = (data) => {
  return data !== undefined && data !== null && data !== false && !Number.isNaN(data);
};

const displayArrow = (firstData, lastData) => {
  return !(!checkTruthy(firstData) && checkTruthy(lastData));
};

const calculatePercentageChange = (current, previous) => {
  if (current === previous || (current === 0 && previous === 0) || (!current && !previous)) {
    return {
      percentageChange: '0%',
      arrow: null,
      color: 'disabled'
    };
  } else if (!checkTruthy(current) && checkTruthy(previous)) {
    return {
      percentageChange: '100%',
      arrow: <GreenArrowSVG />,
      color: 'success'
    };
  } else {
    const percentageChange = Math.round((current / previous - 1) * 100);
    const arrow = percentageChange < 0 ? <GreenArrowSVG /> : <RedArrowSVG />;
    const color = percentageChange < 0 ? 'success' : 'error';

    return {
      percentageChange: `${Math.abs(percentageChange)}%`,
      arrow,
      color
    };
  }
};

const processData = (result) => {
  // Object to store aggregated sums for each metric type
  const basket = {};

  // Traverse through each key in the result
  for (const key in result) {
    const clarifyData = result[key]?.clarifyData;

    // Check if clarifyData exists and iterate over each metric type
    if (clarifyData) {
      for (const metric in clarifyData) {
        // Ensure metric has an entry in the basket
        if (!basket[metric]) {
          basket[metric] = 0;
        }

        // Sum up all timestamped values for this metric
        for (const timestamp in clarifyData[metric]) {
          const value = parseFloat(clarifyData[metric][timestamp]) || 0;
          basket[metric] += value;
        }
      }
    }
  }

  return basket;
};

function Insights() {
  const dispatch = useDispatch();
  const { valueWithUnit } = useDisplayUnit();
  const selectedRange = useSelector(getInsightsRange);

  const results = useQueries({
    queries: [
      {
        queryKey: ['insight', 'current', { selectedRange }],
        placeholderData: {},
        queryFn: ({ signal }) => {
          let startOf = 'day';
          if (selectedRange.unit === 'days' && selectedRange.number === 1) {
            startOf = 'hour';
          }
          const endDate = moment.utc().startOf(startOf);
          const startDate = endDate.clone().subtract(selectedRange?.number, selectedRange?.unit);

          return fetchOverviewKeyFiguresDataV2(
            signal,
            {
              selectedRig: { rigName: '', rigId: 0 },
              startDate: startDate.format(UTC_TIMESTAMP),
              endDate: endDate.format(UTC_TIMESTAMP)
            },
            API_ROLLUP.MIN
          )
            .then((resp) => {
              if (resp) {
                return processData(resp);
              }
              return {};
            })
            .catch(() => {});
        }
      },
      {
        queryKey: ['insight', 'previous', { selectedRange }],
        placeholderData: {},
        queryFn: ({ signal }) => {
          let startOf = 'day';
          if (selectedRange.unit === 'days' && selectedRange.number === 1) {
            startOf = 'hour';
          }
          const endDate = moment
            .utc()
            .startOf(startOf)
            .subtract(selectedRange?.number, selectedRange?.unit);
          const startDate = endDate.clone().subtract(selectedRange?.number, selectedRange?.unit);
          return fetchOverviewKeyFiguresDataV2(
            signal,
            {
              selectedRig: { rigName: '', rigId: 0 },
              startDate: startDate.format(UTC_TIMESTAMP),
              endDate: endDate.format(UTC_TIMESTAMP)
            },
            API_ROLLUP.MIN
          )
            .then((resp) => {
              if (resp) {
                return processData(resp);
              }
              return {};
            })
            .catch(() => {});
        }
      }
    ]
  });

  let loading = results.some((item) => item.isFetching);

  const { totalEnergyConsumption, totalFuelConsumption, totalCo2Emissions, totalNoXEmissions } =
    useMemo(() => {
      if (loading) return {};
      const { data } = results[0];
      const { data: prevData } = results[1];

      const totalEnergyConsumption = calculatePercentageChange(
        data?.RigEnergy,
        prevData?.RigEnergy
      );
      const totalFuelConsumption = calculatePercentageChange(data?.RigFuel, prevData?.RigFuel);
      const totalCo2Emissions = calculatePercentageChange(data?.RigCO2, prevData?.RigCO2);
      const totalNoXEmissions = calculatePercentageChange(data?.RigNOx, prevData?.RigNOx);

      return {
        totalEnergyConsumption,
        totalFuelConsumption,
        totalCo2Emissions,
        totalNoXEmissions
      };
    }, [loading]);

  const setRangeData = (val) => {
    dispatch(setInsightsRange(val));
  };
  const { data = {} } = results[0];
  const { data: prevData = {} } = results[1];

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Box>
              <Typography variant={'h6'}>Fleet Insight</Typography>
              <TextWithTooltipIcon
                tooltipText={
                  'Sum of all rigs with GPR Live. The time period of the metrics can be selected to the right to display last 24hrs, last 7 days etc.'
                }
                label={<Typography variant={'body2'}>Sum for all GP connected rigs</Typography>}
              />
            </Box>
          </Stack>
          <PeriodRangeButtons
            options={[
              DISPLAY_PERIOD.LAST_DAY,
              DISPLAY_PERIOD.LAST_WEEK,
              DISPLAY_PERIOD.LAST_MONTH,
              DISPLAY_PERIOD.LAST_YEAR
            ]}
            onClick={setRangeData}
            value={selectedRange}
            insights
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <InsightDisplayValue
            title={'Total Energy consumption'}
            value={valueWithUnit(data?.RigEnergy || 0, DISPLAY_UNIT.ENERGY)}
            loading={loading}
            subtitle={selectedRange?.display}
            trend={totalEnergyConsumption}
            displayArrow={displayArrow(prevData?.RigEnergy, data?.RigEnergy)}
          />
          <InsightDisplayValue
            title={'Total Fuel consumption'}
            value={valueWithUnit(data?.RigFuel || 0, DISPLAY_UNIT.FUEL)}
            loading={loading}
            subtitle={selectedRange?.display}
            trend={totalFuelConsumption}
            displayArrow={displayArrow(prevData?.RigFuel, data?.RigFuel)}
          />
          <InsightDisplayValue
            title={
              <Typography variant={'body2'}>
                Total CO<sub>2</sub>e emissions
              </Typography>
            }
            value={valueWithUnit(data?.RigCO2 || 0, DISPLAY_UNIT.CO2)}
            loading={loading}
            subtitle={selectedRange?.display}
            trend={totalCo2Emissions}
            displayArrow={displayArrow(prevData?.RigCO2, data?.RigCO2)}
          />
          <InsightDisplayValue
            title={
              <Typography variant={'body2'}>
                Total NO<sub>x</sub> emissions
              </Typography>
            }
            value={valueWithUnit(data?.RigNOx || 0, DISPLAY_UNIT.NOX)}
            loading={loading}
            subtitle={selectedRange?.display}
            trend={totalNoXEmissions}
            displayArrow={displayArrow(prevData?.RigNOx, data?.RigNOx)}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

Insights.propTypes = {};
Insights.defaultProps = {};

export default Insights;

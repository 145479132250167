import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@esgian/esgianui';
import { TextWithTooltipIcon, TopThreeDrillingActivities } from '@components';
import { useDisplayUnit } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getValue, parseNumberToSignificant } from '@helpers';
import { DISPLAY_UNIT } from '@constants';
import { TotalPerDrillingActivityChart } from '@components/Charts';

function DrillingActivitySummary({ drillingData, loading }) {
  const displayUnit = useSelector(getDisplayUnit);
  const { getUnitTitle, convertUnitValue } = useDisplayUnit();

  const {
    totalSeries = [],
    totalCategories = [],
    unit = ''
  } = useMemo(() => {
    if (!drillingData?.length) return {};
    let series = [];
    let max = 0;
    drillingData.forEach(({ activity, drillingActivityEvents }) => {
      let sum = Object.values(drillingActivityEvents[`drilling${displayUnit}`])?.reduce((a, b) => {
        return a + getValue(b);
      }, 0);
      max = max < sum ? sum : max;
      let tempName = activity;
      if (tempName === 'Uncategorized') {
        tempName = 'Activity data missing';
      }
      series.push({ name: tempName, value: sum });
    });
    const { title, converted } = getUnitTitle(parseNumberToSignificant(max));
    series.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
    return {
      unit: title,
      totalSeries: [
        {
          data: series?.map(({ value }) => {
            if (converted) {
              return convertUnitValue(value, displayUnit);
            }
            return parseNumberToSignificant(value);
          })
        }
      ],
      totalCategories: series?.map(({ name }) => name)
    };
  }, [drillingData, displayUnit]);

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Total drilling equipment CO2e emissions in period';
      case DISPLAY_UNIT.NOX:
        return 'Total drilling equipment NOx emissions in period';
      case DISPLAY_UNIT.FUEL:
        return 'Total drilling equipment fuel consumption in period';
      case DISPLAY_UNIT.ENERGY:
        return 'Total drilling equipment energy consumption in period';
    }
  }, [displayUnit]);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <TextWithTooltipIcon
            tooltipText={
              'Total drilling equipment consumption or emissions for each activity in the selected time period.'
            }
            label={<Typography variant={'h6'}>Activity summary</Typography>}
          />
          <Typography variant="body2">{getSubTitle}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TotalPerDrillingActivityChart
            unit={unit}
            totalSeries={totalSeries}
            totalCategories={totalCategories}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TopThreeDrillingActivities
            loading={loading}
            totalSeries={totalSeries}
            totalCategories={totalCategories}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

DrillingActivitySummary.propTypes = {
  drillingData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

DrillingActivitySummary.defaultProps = {
  drillingData: null,
  loading: false
};

export default DrillingActivitySummary;

import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';

function KeyValueTooltip({ label, value, unit, displayUnit }) {
  return (
    <Stack style={{ minWidth: '200px' }}>
      <span style={{ fontSize: '16px', color: 'inherit', fontWeight: '600' }}>{displayUnit}:</span>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography style={{ color: 'inherit' }} variant={'body2'}>
          {label}:
        </Typography>
        <Typography style={{ color: 'inherit' }} variant={'body2'}>{`${value} ${unit}`}</Typography>
      </div>
    </Stack>
  );
}

KeyValueTooltip.propTypes = {
  displayUnit: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string
};

KeyValueTooltip.defaultProps = {
  displayUnit: '',
  label: '',
  value: '',
  unit: ''
};

export default KeyValueTooltip;

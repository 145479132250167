import { createSlice } from '@reduxjs/toolkit';
import { APP_NAME, DATE_FORMAT, STORE_VERSION, TIME_ZONE } from '@constants';
import moment from 'moment-timezone';
import tzLookup from 'tz-lookup';

const getSessionState = () => {
  try {
    const serializedState = sessionStorage.getItem(APP_NAME);
    if (!serializedState) return undefined;
    const stateObj = JSON.parse(serializedState);

    if (stateObj.version !== STORE_VERSION) {
      return undefined;
    }
    return stateObj.filters.mainPage;
  } catch (e) {
    return undefined;
  }
};

const initialState = {
  selectedRig: null,
  startDate: moment().subtract(16, 'days').format(DATE_FORMAT),
  endDate: moment().subtract(2, 'days').format(DATE_FORMAT),
  selectedDateName: '',
  selectedDateType: null,
  selectedSection: 1,
  timeZone: TIME_ZONE.RIGS_LOCATION,
  customUTC: undefined,
  rigUTC: 'Europe/Oslo',
  insightsRange: {
    index: 1,
    unit: 'days',
    number: 1,
    display: '24 hours'
  }
};

const MainPageSlice = createSlice({
  name: 'MainPage',
  initialState: getSessionState() || initialState,
  reducers: {
    resetMainSlice: () => initialState,
    setMainPageSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setMainPageRig: (state, action) => {
      const rig = action.payload;
      state.selectedRig = rig;
      state.rigUTC = tzLookup(rig?.latitude, rig?.longitude);
    },
    setMainPagePeriod: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.selectedDateName = action.payload.name;
      state.selectedDateType = action.payload.type || null;
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload;
      state.selectedDateType = action.payload.type || null;
    },
    setCustomUTC: (state, action) => {
      state.customUTC = action.payload;
    },
    setRigUTC: (state, action) => {
      state.rigUTC = action.payload;
      state.selectedDateType = action.payload.type || null;
    },
    setInsightsRange: (state, action) => {
      state.insightsRange = action.payload;
    }
  }
});

export const getMainPageSlice = (state) => state.filters.mainPage;
export const getMainPageSelectedSection = (state) => state.filters.mainPage.selectedSection;
export const getMainPagePeriod = (state) => {
  return { startDate: state.general.startDate, endDate: state.filters.mainPage.endDate };
};
export const getMainPageRig = (state) => {
  return state.filters.mainPage.selectedRig;
};
export const getTimeZone = (state) => state.filters.mainPage.timeZone;
export const getCustomUTC = (state) => state.filters.mainPage.customUTC;
export const getRigUTC = (state) => state.filters.mainPage.rigUTC;
export const getInsightsRange = (state) => state.filters.mainPage.insightsRange;
export const getMainDurationHours = (state) => {
  let start = state.filters.mainPage.startDate;
  let end = state.filters.mainPage.endDate;
  if (start && end) {
    let momentEnd = moment.utc(end, DATE_FORMAT).endOf('day');
    if (momentEnd.isAfter(moment.utc())) {
      momentEnd = moment.utc();
    }
    return Math.ceil(moment.duration(momentEnd.diff(moment.utc(start, DATE_FORMAT))).asHours());
  }
  return 0;
};
export const {
  setMainPageSection,
  setMainPageRig,
  setMainPagePeriod,
  setTimeZone,
  setCustomUTC,
  setRigUTC,
  resetMainSlice,
  setInsightsRange
} = MainPageSlice.actions;

export default MainPageSlice.reducer;

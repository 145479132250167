import React, { useCallback } from 'react';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getMyBaselines } from '@store/features';
import PropTypes from 'prop-types';
import BaselineTypeLabel from '@components/Inputs/BaselineInput/BaselineModal/BaselineTypeLabel';

function BaselineSelect({
  selectedBaseline,
  onChange,
  disabled,
  required,
  showTitle,
  id,
  error,
  label
}) {
  const { customScrollbar } = useTheme();
  const lookupBaselines = useSelector(getMyBaselines);

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;
      const baseline = lookupBaselines?.find(({ baselineId }) => baselineId === parseInt(value));
      if (!baseline) return;
      onChange(baseline);
    },
    [lookupBaselines, onChange] // Also add `onChange` to the dependency array
  );

  return (
    <Stack id={id}>
      <FormControl sx={{ minWidth: 224 }} size="small" error={error}>
        {/* Select Component inside the FormControl */}
        <TextField
          select
          label={label}
          disabled={disabled}
          value={selectedBaseline?.baselineId ?? ''}
          onChange={handleChange}
          SelectProps={{
            displayEmpty: true,
            MenuProps: {
              slotProps: { paper: { sx: { ...customScrollbar } } },
              sx: { ...customScrollbar, maxHeight: '40vh' }
            },
            renderValue: (selected) => {
              if (!selected || !lookupBaselines.length) return 'Select Baseline'; // Placeholder
              const baseline = lookupBaselines.find(({ baselineId }) => baselineId === selected);
              return baseline ? baseline.baselineName : 'Select Baseline';
            }
          }}
          inputProps={{
            sx: { padding: '12px 8px' }
          }}>
          {/* When baselines are loading */}
          {!lookupBaselines.length ? (
            <MenuItem disabled>
              <CircularProgress size={25} />
            </MenuItem>
          ) : (
            lookupBaselines.map(({ baselineName, baselineTypeId, baselineId }) => (
              <MenuItem
                key={`baseline-select-${baselineId}`}
                value={baselineId}
                sx={{
                  '&:hover': {
                    color: ({ palette }) => palette.primary.main
                  }
                }}>
                <Stack
                  sx={{ width: '100%' }}
                  justifyContent="space-between"
                  direction="row"
                  spacing={2}>
                  <Typography variant="body1">{baselineName}</Typography>
                  <BaselineTypeLabel baselineTypeId={baselineTypeId} />
                </Stack>
              </MenuItem>
            ))
          )}
        </TextField>
      </FormControl>
    </Stack>
  );
}

BaselineSelect.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showTitle: PropTypes.bool,
  selectedBaseline: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool
};

BaselineSelect.defaultProps = {
  showTitle: false,
  required: false,
  onChange: undefined,
  selectedBaseline: null,
  helperText: null,
  disabled: false,
  error: false,
  label: 'Baseline',
  id: 'baseline-select'
};

export default BaselineSelect;

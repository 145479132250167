import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, PlusIcon, Stack, Typography } from '@esgian/esgianui';

function NoBaselinesDisplay({ handleCreateClick }) {
  return (
    <Box sx={{ pt: 5, pb: 5, pr: 2, pl: 2 }}>
      <Box
        sx={{
          p: 2,
          borderRadius: '8px',
          backgroundImage: ({ palette }) =>
            `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${palette.secondary.main.slice(
              1
            )}FF' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
        }}>
        <Stack spacing={2} alignItems={'center'}>
          <Typography variant={'body1'}>Create a baseline to get started</Typography>
          <Button
            onClick={handleCreateClick}
            sx={{ width: 'fit-content' }}
            startIcon={<PlusIcon color={'inherit'} />}
            variant={'outlined'}>
            Create a baseline
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

NoBaselinesDisplay.propTypes = {
  handleCreateClick: PropTypes.func.isRequired
};

NoBaselinesDisplay.defaultProps = {};

export default NoBaselinesDisplay;

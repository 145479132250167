import { createListenerMiddleware } from '@reduxjs/toolkit';
import { fetchLookupData } from '@store/features';

/**
 * Fetch lookup data when a user is logged in
 * */

export const getUserMiddleware = () => {
  const userMiddleware = createListenerMiddleware();
  // Add one or more listener entries that look for specific actions.
  // They may contain any sync or async logic, similar to thunks.
  userMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
      return currentState.auth.user !== previousState.auth.user && currentState.auth.hasAccess;
    },
    effect: (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      const user = listenerApi.getState().auth.user;
      listenerApi.dispatch(fetchLookupData({ token: user.token, userId: user.id }));
    }
  });

  return userMiddleware;
};

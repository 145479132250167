import { configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash.throttle';
import AuthSlice from './features/auth/AuthSlice';
import DataSlice from './features/data/DataSlice';
import GeneralSlice from './features/general/GeneralSlice';
import { APP_NAME, STORE_VERSION } from '@constants';
import FiltersReducer from '@store/features/filters/FiltersReducer';
import { getUserMiddleware } from '@store/middleware/userMiddleware';
// Create the middleware instance and methods

const userMiddleware = getUserMiddleware();

export const store = configureStore({
  reducer: {
    general: GeneralSlice,
    auth: AuthSlice,
    data: DataSlice,
    filters: FiltersReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend([userMiddleware.middleware])
});

store.subscribe(
  throttle(() => {
    const state = store.getState();
    const serializedState = JSON.stringify({ version: STORE_VERSION, ...state });
    sessionStorage.setItem(APP_NAME, serializedState);
  }, 1000)
);

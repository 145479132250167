import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowDropDownIcon, Box, CircularProgress, TextField } from '@esgian/esgianui';
import BaselineModal from '@components/Inputs/BaselineInput/BaselineModal/BaselineModal';
import BaselinePopover from '@components/Inputs/BaselineInput/BaselinePopover';
import { useSelector } from 'react-redux';
import { getLookupIsLoading, getMyBaselines } from '@store/features';

function BaselineInput({ value }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const loading = useSelector(getLookupIsLoading);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('create');
  const lookupBaselines = useSelector(getMyBaselines);

  return (
    <Box>
      <TextField
        inputProps={{
          sx: { padding: '12px 8px', textAlign: 'start' }
        }}
        type={'button'}
        disabled={loading}
        label={!loading ? 'My baseline' : <CircularProgress color={'inherit'} size={25} />}
        autoComplete={'off'}
        value={value ? `${value.baselineName}` : '-'}
        size={'small'}
        sx={{ width: '510px' }}
        InputProps={{
          endAdornment: <ArrowDropDownIcon fontSize={'medium'} />
        }}
        onClick={({ currentTarget }) => {
          if (!loading) {
            setAnchorEl(currentTarget);
          }
        }}
      />
      <BaselinePopover
        selectedBaseline={value}
        anchorEl={anchorEl}
        baselines={lookupBaselines}
        setAnchorEl={setAnchorEl}
        setModalOpen={setModalOpen}
        setModalType={setModalType}
      />
      {modalOpen && (
        <BaselineModal
          modalType={modalType}
          handleClose={() => {
            setModalOpen(false);
          }}
        />
      )}
    </Box>
  );
}

BaselineInput.propTypes = {
  value: PropTypes.object
};

BaselineInput.defaultProps = {
  value: null
};

export default BaselineInput;

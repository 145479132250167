import { BaseApiRequest } from '@api/BaseApi';
import { getToken } from '@store/helpers';

export const getRigsOverview = (token) => {
  return BaseApiRequest(null, `/RigPosition`, 'gpr', 'POST', {
    token: token || getToken()
  });
};
export const getUserBaselines = (token, userId) => {
  return BaseApiRequest(null, `/Baseline/GetByUserId`, 'gpr', 'POST', {
    token: token || getToken(),
    userId: userId
  });
};

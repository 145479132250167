let crosshair;

/**
 * Options
 * @param lineColor color for the hoverline
 * @param disabled disable hover line
 * @param syncRefs array of reference to other charts
 *
 * */
export const hoverLinePlugin = {
  id: 'hoverLinePlugin',
  events: ['mousemove'],
  afterRender: (chart, args, options) => {
    if (chart) {
      let { ctx } = chart;
      if (crosshair) {
        crosshair.forEach((line) => {
          ctx.save();
          ctx.beginPath();
          ctx.lineWidth = 1;
          ctx.setLineDash([3]);
          ctx.strokeStyle = options.lineColor;
          ctx.moveTo(line.startX, line.startY);
          ctx.lineTo(line.endX, line.endY);
          ctx.stroke();
          ctx.closePath();
        });
        ctx.restore();
      }
    }
  },
  afterEvent: (chart, args, options) => {
    if (options.disabled) return;
    const {
      chartArea: { top, bottom }
    } = chart;

    const activePoints = chart.getElementsAtEventForMode(args.event, 'index', {
      intersect: false
    });
    if (!args.inChartArea && crosshair) {
      crosshair = undefined;
      args.changed = true;
    } else if (args.inChartArea && activePoints?.length) {
      crosshair = [
        {
          startX: activePoints[0].element.x,
          startY: top,
          endX: activePoints[0].element.x,
          endY: bottom
        }
      ];
      args.changed = true;
    }

    if (options.disabled) return;
    options.syncRefs?.forEach((chartRef) => {
      if (chartRef.current) {
        let secondCtx = chartRef.current.ctx;
        const {
          chartArea: { top, bottom }
        } = chartRef.current;

        chartRef.current.render();
        if (crosshair) {
          crosshair.forEach((line) => {
            secondCtx.beginPath();
            secondCtx.lineWidth = 1;
            secondCtx.setLineDash([3]);
            secondCtx.strokeStyle = options.lineColor;
            secondCtx.moveTo(line.startX, top);
            secondCtx.lineTo(line.endX, bottom);
            secondCtx.stroke();
          });
          secondCtx.restore();
        }
      }
    });
  }
};

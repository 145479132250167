import React from 'react';
import PropTypes from 'prop-types';
import { BASELINE_CHART_TYPE } from '@constants';
import { MenuItem, TextField, Typography } from '@esgian/esgianui';

function BaselineChartTypeSelect({ disabled, handleChange, value }) {
  return (
    <TextField
      select
      disabled={disabled}
      label={'Chart Type'}
      variant={'outlined'}
      inputProps={{ sx: { width: '150px', p: 1, placeContent: 'center' } }}
      value={value || ''}
      onChange={({ target }) => handleChange(parseInt(target.value))}>
      <MenuItem value={BASELINE_CHART_TYPE.BY_TIME}>
        <Typography variant={'body2'}>By time</Typography>
      </MenuItem>
      <MenuItem value={BASELINE_CHART_TYPE.BY_ACTIVITIES}>
        <Typography variant={'body2'}>By activities</Typography>
      </MenuItem>
    </TextField>
  );
}

BaselineChartTypeSelect.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(BASELINE_CHART_TYPE))
};

BaselineChartTypeSelect.defaultProps = { disabled: false, value: BASELINE_CHART_TYPE.BY_TIME };

export default BaselineChartTypeSelect;

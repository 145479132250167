export const yAxisTitle = {
  id: 'yAxisTitle',
  afterDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { top }
    } = chart;
    ctx.save();
    ctx.font = `${options.fontSize}} Roboto`;
    ctx.fillStyle = options.fontColor;
    let x = 15 / options.text?.length;
    ctx.fillText(options.text, x, top - 15);
    ctx.restore();
  }
};

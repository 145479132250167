import React, { useCallback, useMemo } from 'react';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { VIEW_BY_TYPE } from '@constants';
import { BaselineComparisonChart } from '@components/Charts';
import LookupSelect from '@components/Inputs/LookupSelect';
import DataTypeSelect from '../../../../Inputs/DataTypeSelect';
import AnalyticsComparisonKpis from '@components/Sections/BaselineComparisonSection/SubSections/AnalyticsComparison/AnalyticsComparisonKpis';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import {
  getBaselineComparisonSlice,
  setAnalyticsFilters
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { LOOKUP_FOCUS_AREA } from '@lookups';
import { useBaselineComp } from '@hooks/useBaselineComp';
import HourDaySelect from '../../../../Inputs/HourDaySelect';
import { BaselineChartTypeSelect } from '@components/Inputs';
import { useBaselineQueries } from '@hooks';

function AnalyticsComparison() {
  const displayUnit = useSelector(getDisplayUnit);
  const dispatch = useDispatch();
  const { getAnalyticsSeries } = useBaselineComp();
  const { baselineAnalyticsQueries } = useBaselineQueries();
  const {
    analytics: { dataType, focusArea, viewBy, chartType }
  } = useSelector(getBaselineComparisonSlice);

  const handleChange = useCallback((val) => {
    dispatch(setAnalyticsFilters(val));
  }, []);

  const mainData = baselineAnalyticsQueries[0].data;
  const baselineData = baselineAnalyticsQueries[1].data;

  const {
    mainSeries = [],
    baseSeries = [],
    categories = [],
    unit,
    max = 0
  } = useMemo(() => {
    if (!mainData?.length || !baselineData?.length) return {};
    return getAnalyticsSeries(mainData, baselineData);
  }, [displayUnit, mainData, baselineData, dataType, focusArea, viewBy]);

  const loading = baselineAnalyticsQueries?.some((item) => item.isFetching);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant={'h6'}>Analytics - comparison of potential saved energy</Typography>
        </Stack>
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparing to the Baseline
        </Typography>
        <AnalyticsComparisonKpis
          loading={loading}
          baselineData={baselineData}
          mainData={mainData}
        />
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparison over time
        </Typography>
        <BaselineComparisonChart
          chartActions={[
            <LookupSelect
              options={LOOKUP_FOCUS_AREA}
              label={'Focus area'}
              key={'focus-area-select'}
              value={focusArea}
              onChange={(val) => {
                handleChange({ focusArea: val });
              }}
            />,
            <BaselineChartTypeSelect
              key={'baseline-chart-type-select'}
              value={chartType}
              handleChange={(val) => {
                handleChange({ chartType: val });
              }}
            />,
            <DataTypeSelect
              key={'data-type-select'}
              value={dataType}
              onChange={(val) => {
                handleChange({ dataType: val });
              }}
            />,
            <HourDaySelect
              key={'view-by-select'}
              value={viewBy}
              handleChange={(val) => {
                handleChange({ viewBy: val });
              }}
            />
          ]}
          viewBy={viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour'}
          dataType={dataType}
          yAxisTitle={unit}
          mainMax={max}
          chartType={chartType}
          loading={loading}
          mainSeries={mainSeries}
          baseSeries={baseSeries}
          categories={categories}
        />
      </Stack>
    </Paper>
  );
}

AnalyticsComparison.propTypes = {};

AnalyticsComparison.defaultProps = {};

export default AnalyticsComparison;

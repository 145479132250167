import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Typography } from '@esgian/esgianui';

function SimpleBarTooltip({ children, unit, title, value, backgroundColor, textColor }) {
  return (
    <Paper
      variant={'outlined'}
      style={{
        padding: '16px 8px 16px 8px',
        background: backgroundColor,
        borderWidth: 0,
        color: textColor
      }}>
      {children}
      {!children && (
        <Stack spacing={1}>
          <Typography style={{ color: textColor }} bold variant={'body2'}>
            {unit}:
          </Typography>
          <Stack direction={'row'} spacing={1}>
            <Typography style={{ color: textColor }} variant={'body2'}>
              {title}:
            </Typography>
            <Typography style={{ color: textColor }} variant={'body2'}>
              {value}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
}

SimpleBarTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  unit: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SimpleBarTooltip.defaultProps = {
  title: '',
  children: null,
  value: '',
  backgroundColor: '',
  unit: 'Energy',
  textColor: ''
};

export default SimpleBarTooltip;

import React, { useEffect, useMemo, useState } from 'react';
import { ApexChart, getEsgianTheme, Skeleton, Stack, Typography } from '@esgian/esgianui';
import { APP_NAME } from '@constants';
import { LegendValue } from '@components';
import PropTypes from 'prop-types';
import TextWithTooltipIcon from '../../Display/TextWithTooltipIcon';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

const getOptions = (theme) => {
  const { palette: { mode, text, charts: { background, twoColorsContrastSecondary } } = {} } =
    theme || {};
  return {
    chart: {
      animations: {
        enabled: false
      },
      type: 'donut',
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      background: background
    },
    colors: [twoColorsContrastSecondary[0]],
    stroke: {
      width: 0
    },

    plotOptions: {
      radialBar: {
        hollow: {
          size: '65%'
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 25,
            show: true,
            color: text.primary,
            fontSize: '14px',
            fontWeight: 400
          },
          value: {
            offsetY: -10,
            fontSize: '32px',
            show: true,
            formatter: function (val) {
              return val.toFixed(0) + '%';
            }
          }
        },
        track: {
          background: twoColorsContrastSecondary[1],
          stroke: 'red',
          opacity: 1,
          strokeWidth: '100%'
        }
      }
    },
    fill: {
      opacity: 1
    },
    dataLabels: {
      enabled: false
    },
    labels: ['Focus areas', 'Normal'],

    theme: {
      mode: mode
    }
  };
};

function EnergyMonitorWarningsChart({ percent, loading }) {
  const [options, setOptions] = useState(null);
  const themeMode = useSelector(getThemeMode);

  const theme = useMemo(() => {
    return getEsgianTheme(themeMode ? 'dark' : 'light', APP_NAME);
  }, [themeMode]);

  useEffect(() => {
    setOptions(getOptions(theme));
  }, [themeMode]);

  return (
    <Stack alignItems={'center'}>
      <TextWithTooltipIcon
        tooltipText={'Focus area intensity in the selected time period.'}
        label={
          <Typography variant={'subtitle2'} bold>
            Focus area intensity
          </Typography>
        }
      />

      {loading ? (
        <Skeleton sx={{ mt: 2, mb: 2 }} height={'150px'} width={'150px'} variant={'circular'} />
      ) : (
        <ApexChart
          newLoadingAnimation
          loading={loading}
          height={240}
          options={options || {}}
          type={'radialBar'}
          data={[percent]}
        />
      )}
      <Stack direction={'row'} spacing={2}>
        <LegendValue value={'Normal'} color={{ name: 'twoColorsContrastSecondary', index: 1 }} />
        <LegendValue
          value={'Focus areas'}
          color={{ name: 'twoColorsContrastSecondary', index: 0 }}
        />
      </Stack>
    </Stack>
  );
}

EnergyMonitorWarningsChart.propTypes = {
  percent: PropTypes.number,
  loading: PropTypes.bool
};

EnergyMonitorWarningsChart.defaultProps = {
  percent: 0,
  loading: false
};

export default EnergyMonitorWarningsChart;

import { APP_NAME } from '@constants';

export const BaseApiRequest = async (
  signal,
  path,
  service = 'gpr',
  method = 'GET',
  body = undefined,
  headers = {}
) => {
  const sessionState = JSON.parse(sessionStorage.getItem(APP_NAME));
  let apiKey = process.env.API_KEY;

  let uri = process.env.GPR_API;
  if (service === 'auth') {
    uri = process.env.AUTH_API;
  }
  if (service === 'ddr') {
    uri = process.env.DDR_API;
  }

  let reqSignal = {};
  if (service !== 'auth') {
    reqSignal = { signal: signal };
  }
  uri = `${uri}${path}`;
  const requestHeaders = {
    userId: sessionState?.auth?.user?.id || null,
    browserId: 1,
    sameSite: 'none',
    withCredentials: true,
    EsgianApiKey: apiKey,
    SupportsCredentials: true,
    'access-control-allow-origin': '*',
    'Access-Control-Allow-Headers':
      'X-Requested-With, X-HTTP-Method-Override, Content-Type, Accept',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,UPDATE,OPTIONS',
    'Access-Control-Allow-Credentials': true,
    'sec-fetch-site': 'same-origin',
    'Content-type': method === 'download' ? 'application/json' : 'application/json',
    'Keep-Alive': 'timeout=100, max=100',
    ...headers
  };

  return await fetch(uri, {
    ...reqSignal,
    mode: 'cors',
    credentials: 'include',
    method: method === 'download' ? 'POST' : method,
    body: JSON.stringify(body),
    headers: requestHeaders
  })
    .then(async (response) => {
      const reject = (errors) => {
        return Promise.reject({
          status: response.status,
          errors
        });
      };

      if (response.status === 401) {
        return reject(['Unauthorized']);
      }
      if (response.status === 403) {
        return reject(['Forbidden']);
      } else if (response.status === 404) {
        return reject(['Requested URL does not exist']);
      } else if (response.status === 412) {
        return reject(['No data was found in clarify']);
      } else if (response.status === 500) {
        return reject(['Internal server error']);
      } else if (response.status === 504) {
        return reject(['Request timed out']);
      }

      try {
        let data;
        data = await response;
        if (method === 'download') {
          return response.blob();
        }
        if (method === 'DELETE' || method === 'PUT') {
          return response;
        }
        data = response?.json();
        if (response.ok) {
          return data;
        }

        return reject(data[Object.keys(data)[0]]);
      } catch (e) {
        return reject(['Unknown error.']);
      }
    })
    .catch((err) => {
      if (err?.errors?.length && typeof err?.errors[0] === 'string') {
        return Promise.reject({
          status: err.status ? err.status : undefined,
          errors: typeof err.errors === 'string' ? [err.errors] : err.errors
        });
      }

      return Promise.reject({ status: 500, errors: ['Network error'] });
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { SimpleErrorPage } from '@esgian/esgianui';
import useAppInsights from '@hooks/useAppInsights';
import { useSelector } from 'react-redux';
import { APP_NAME } from '@constants';
import { getFiltersSlice } from '@store/features/filters/FiltersReducer';

function fallbackRender({ resetErrorBoundary }) {
  return (
    <SimpleErrorPage
      handleReset={() => {
        sessionStorage.removeItem(APP_NAME);
        resetErrorBoundary();
        window.location.replace('/');
      }}
      header={'Something went wrong'}
      subHeader={'This error has been reported. Try to reset the state if the problem persists.'}
      onClick={() => {
        resetErrorBoundary();
        window.location.replace('/');
      }}
    />
  );
}

function ErrorBoundaryHandler({ children }) {
  let { logError } = useAppInsights();
  const filters = useSelector(getFiltersSlice);
  const onError = (error) => {
    let tempPath = window.location.pathname + window.location.search;
    logError(error, { currentUri: tempPath, state: JSON.stringify(filters) });
  };

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={onError}>
      {children}
    </ErrorBoundary>
  );
}

ErrorBoundaryHandler.propTypes = {
  children: PropTypes.node.isRequired
};

ErrorBoundaryHandler.defaultProps = {};

export default ErrorBoundaryHandler;

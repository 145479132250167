import React from 'react';
import { CircularProgress, Grid } from '@esgian/esgianui';

function LoadingScreen() {
  return (
    <Grid container sx={{ height: '100vh' }} justifyContent={'center'} alignItems={'center'}>
      <Grid item>
        <CircularProgress size={'5em'} />
      </Grid>
    </Grid>
  );
}

LoadingScreen.propTypes = {};

LoadingScreen.defaultProps = {};

export default LoadingScreen;

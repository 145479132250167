import moment from 'moment-timezone';

const STAGING_COOKIE_EXT = process.env.COOKIE_STAGE === '_test' ? '.stage' : '';
const TOKEN_STRING = `esgian_auth${process.env.COOKIE_STAGE}${STAGING_COOKIE_EXT}`;
const STAGING_URI_EXT = process.env.COOKIE_STAGE === '_test' ? '.qa' : '';

const DOMAIN_STRING =
  location.hostname !== '' ? `; domain=${STAGING_URI_EXT}${process.env.COOKIE_DOMAIN}` : '';
const ACCEPTANCE_STRING_RS = 'disclaimer-gpr';

export const logout = () => {
  document.cookie = `${TOKEN_STRING}=; expires=Thu, 01 Jan 1970 00:00:00 UTC${DOMAIN_STRING}; path=/`;
  document.cookie = `${ACCEPTANCE_STRING_RS}=; expires=Thu, 01 Jan 1970 00:00:00 UTC${DOMAIN_STRING}; path=/`;
};

export const getToken = () => {
  return document.cookie
    .split(';')
    .find((e) => e.includes(TOKEN_STRING))
    ?.split('=')[1];
};

export const setToken = (token, remember = false) => {
  // Delete old tokens, if exist
  logout();
  if (remember) {
    const expireDate = moment().add(1, 'years');
    document.cookie = `${TOKEN_STRING}=${token}; expires=${expireDate}${DOMAIN_STRING}; path=/`;
  } else {
    document.cookie = `${TOKEN_STRING}=${token}${DOMAIN_STRING}; path=/`;
  }
};

export const setDisclaimer = () => {
  const expireDate = moment().add(1, 'month');
  document.cookie = `${ACCEPTANCE_STRING_RS}=accepted; expires=${expireDate}${DOMAIN_STRING}; path=/`;
};

export const getDisclaimer = () => {
  return document.cookie
    .split(';')
    .find((e) => e.includes(`${ACCEPTANCE_STRING_RS}`))
    ?.split('=')[1];
};

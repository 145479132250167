import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, getEsgianTheme } from '@esgian/esgianui';
import { APP_NAME } from '@constants';
import moment from 'moment-timezone';
import { formatNumberReadable } from '@helpers';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

const getOffsetY = (str = '') => {
  if (str === 'Power details') {
    return -155;
  } else if (str === 'Details') {
    return -142;
  } else if (str === 'Equipment periodic') {
    return -144;
  } else {
    return -130;
  }
};

const getOffsetX = (str = '') => {
  if (str === 'Power details') {
    return 13;
  } else if (str === 'Details') {
    return 6;
  } else if (str === 'Equipment periodic') {
    return 13;
  } else {
    return 13;
  }
};

const getYAxis = (yAxisTitle, secondYAxis, showLegend, detailsTitle, id) => {
  let axis = [
    {
      labels: {
        minWidth: 50,
        maxWidth: 50,
        style: { fontSize: '10px' },
        formatter: (value) => {
          if (parseInt(value) === 0) {
            if (parseFloat(value) > 0) {
              return parseFloat(value).toFixed(2);
            } else {
              return 0;
            }
          }
          if (value <= 5) {
            return parseFloat(value).toFixed(1);
          }
          return formatNumberReadable(parseInt(value));
        }
      },
      decimalsInFloat: 0,
      tickAmount: 5,
      min: id === 'engine-avg-sfoc' ? 150 : 0,
      max: (max) => (yAxisTitle === '%' ? 100 : id === 'engine-avg-sfoc' ? 350 : max * 1.2)
    }
  ];
  if (showLegend && yAxisTitle) {
    axis[0].title = {
      text: yAxisTitle,
      offsetY: getOffsetY(detailsTitle),
      offsetX: getOffsetX(detailsTitle),
      rotate: 0,
      style: {
        fontSize: '10px',
        fontWeight: 100
      }
    };
  }

  if (secondYAxis) {
    axis.push(secondYAxis);
  }
  return axis;
};

const getBarChartOptions = (
  id,
  categories,
  theme,
  stacked,
  yAxisTitle,
  secondYAxis,
  colorPalette,
  setTempPositions,
  setShowTooltip,
  handleColumnClick,
  customCategoryRender,
  chartType,
  labelColors,
  showLegend,
  xAxisType,
  chartOffsetX,
  titleOffsetX,
  detailsTitle
) => {
  const {
    palette: {
      mode,
      charts: { background }
    }
  } = theme;
  let colors = typeof colorPalette === 'string' ? theme.palette.charts[colorPalette] : colorPalette;

  return {
    chart: {
      animations: {
        enabled: false
      },
      id: `-${id}-chart`,
      type: 'bar',
      height: 350,
      offsetX: chartOffsetX,
      width: '100%',
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        show: chartType === 'line',
        tools: {
          reset: true,
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true
        }
      },
      stacked: stacked,
      events: {
        click: (event, chartContext, config) => {
          if (config.dataPointIndex >= 0) {
            handleColumnClick(config.dataPointIndex);
          }
        },
        mouseMove: function (event) {
          let leftP = event.pageX - 320;
          if (event.pageX < 320) {
            leftP = event.pageX;
          }
          setTempPositions({ left: leftP, top: event.pageY + window.scrollY });
        }
      }
    },
    stroke: {
      show: !stacked,
      width: 2,
      curve: 'straight'
    },
    dataLabels: {
      enabled: false
    },
    colors: colors,
    theme: {
      mode: mode
    },
    title: {
      text: showLegend ? undefined : yAxisTitle,
      offsetY: 15,
      offsetX: titleOffsetX,
      rotate: 0,
      style: {
        fontSize: '10px',
        fontWeight: 100
      }
    },
    xaxis: {
      axisTicks: {
        show: false
      },
      type: xAxisType || 'category',
      datetimeUTC: false,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm'
      },
      labels: {
        offsetY: xAxisType === 'datetime' ? -10 : 0,
        style: {
          fontSize: '10px',
          colors: labelColors || undefined
        },
        datetimeUTC: true,

        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
          minute: 'HH:mm'
        },
        rotate: -45,
        rotateAlways: true
      },
      ...(xAxisType !== 'datetime' && {
        categories: customCategoryRender
          ? customCategoryRender()
          : categories?.map((val) => moment(val).format('DD MMM'))
      })
    },
    yaxis: getYAxis(yAxisTitle, secondYAxis, showLegend, detailsTitle, id),
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ dataPointIndex }) {
        setShowTooltip(dataPointIndex);
        return '<div id="' + id + '-tooltip" style="visibility: hidden" />';
      }
    },
    fill: {
      opacity: 1
    },
    grid: {
      padding: { top: showLegend ? 10 : 0 }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%'
      }
    },
    legend: {
      show: showLegend,
      markers: { radius: 0, width: 8, height: 8 },
      position: 'top',
      horizontalAlign: 'left',
      offsetY: xAxisType === 'datetime' ? 0 : -10,
      offsetX: -32
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};

function BarChartWithCustomTooltip({
  loading,
  id,
  series,
  categories,
  height,
  setShowTooltip,
  showTooltip,
  customTooltip,
  yAxisTitle,
  secondYAxis,
  tooltipId,
  stacked,
  chartType,
  colorPalette,
  setTooltipPosition,
  customCategoryRender,
  handleColumnClick,
  labelColors,
  showLegend,
  chartOffsetX,
  xAxisType,
  titleOffsetX,
  detailsTitle
}) {
  const [tempTooltipPosition, setTempTooltipPosition] = useState({ left: 0, top: 0 });
  const themeMode = useSelector(getThemeMode);

  useEffect(() => {
    // Remove the tooltip when user is not hovering the chart
    let chart = document.getElementById(`apexcharts-${id}-chart`);
    if (!chart) return;
    chart?.addEventListener('mouseleave', () => {
      let tooltip = document.getElementById(`${tooltipId}`);
      // Do not remove tooltip if current hover is on the tooltip
      if (!tooltip?.matches(':hover')) {
        setShowTooltip(null);
      }
    });
  }, [series, loading]);

  useEffect(() => {
    // Update the position of the tooltip once the user hovers a new column
    let chart = document.getElementById(id);
    if (!chart) return;
    let pos = chart.getBoundingClientRect();
    setTooltipPosition({ ...tempTooltipPosition, ...{ top: pos.top + window.scrollY - 100 } });
  }, [showTooltip]);

  const options = useMemo(() => {
    const theme = getEsgianTheme(themeMode ? 'dark' : 'light', APP_NAME);
    return getBarChartOptions(
      id,
      categories,
      theme,
      stacked,
      yAxisTitle,
      secondYAxis,
      colorPalette,
      setTempTooltipPosition,
      setShowTooltip,
      handleColumnClick,
      customCategoryRender,
      chartType,
      labelColors,
      showLegend,
      xAxisType,
      chartOffsetX,
      titleOffsetX,
      detailsTitle
    );
  }, [themeMode, categories, yAxisTitle, colorPalette, labelColors]);

  return (
    <>
      {showTooltip !== null && customTooltip}
      <div className={'custom-tooltip-chart'} id={id}>
        <ApexChart
          newLoadingAnimation
          loading={loading || !options}
          height={height}
          options={options || {}}
          type={chartType}
          data={series}
        />
      </div>
    </>
  );
}

BarChartWithCustomTooltip.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  series: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.number])),
  categories: PropTypes.arrayOf(PropTypes.string),
  setShowTooltip: PropTypes.func.isRequired,
  setTooltipPosition: PropTypes.func.isRequired,
  handleColumnClick: PropTypes.func,
  customCategoryRender: PropTypes.func,
  showTooltip: PropTypes.number,
  showLegend: PropTypes.bool,
  yAxisTitle: PropTypes.string,
  xAxisType: PropTypes.string,
  height: PropTypes.number,
  titleOffsetX: PropTypes.number,
  chartOffsetX: PropTypes.number,
  tooltipId: PropTypes.string.isRequired,
  chartType: PropTypes.oneOf(['line', 'bar']),
  colorPalette: PropTypes.oneOfType([
    PropTypes.oneOf([
      'twoColorsContrast',
      'twoColorsContrastSecondary',
      'twoColorsSameType',
      'fourColorsPie',
      'primaryChartColor',
      'secondaryChartColor',
      'sixColorsLine',
      'fourColorsStacked',
      'eighteenColorsStacked'
    ]),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  labelColors: PropTypes.arrayOf(PropTypes.string),
  stacked: PropTypes.bool,
  secondYAxis: PropTypes.object,
  customTooltip: PropTypes.node.isRequired,
  detailsTitle: PropTypes.string
};

BarChartWithCustomTooltip.defaultProps = {
  loading: false,
  stacked: false,
  showLegend: false,
  labelColors: null,
  secondYAxis: null,
  customCategoryRender: null,
  height: 360,
  chartOffsetX: 0,
  titleOffsetX: 5,
  showTooltip: null,
  handleColumnClick: () => {},
  yAxisTitle: 'MWh',
  xAxisType: 'category',
  colorPalette: 'elevenColorIndication',
  chartType: 'bar',
  series: [],
  categories: [],
  detailsTitle: ''
};

export default BarChartWithCustomTooltip;

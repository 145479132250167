import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@esgian/esgianui';

function CustomTooltip({ children, position, id }) {
  return (
    <div
      id={id}
      style={{
        transition: 'all 0.2s ease',
        position: 'absolute',
        width: 'fit-content',
        minWidth: '320px',
        zIndex: 200,
        top: `${position.top}px`,
        left: `${position.left}px`
      }}>
      <Paper
        variant={'outlined'}
        sx={{
          color: ({ palette }) => palette.tooltip.contrastText,
          background: ({ palette }) => palette.tooltip.background
        }}>
        {children}
      </Paper>
    </div>
  );
}

CustomTooltip.propTypes = {
  children: PropTypes.node,
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number
  }),
  id: PropTypes.string.isRequired
};

CustomTooltip.defaultProps = {
  position: {
    top: 0,
    left: 0
  }
};

export default CustomTooltip;

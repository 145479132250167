import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarChart from '../SimpleBarChart';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { useDisplayUnit } from '@hooks';

function TotalPerDrillingActivityChart({ unit, totalSeries, totalCategories, loading }) {
  const { displayUnit } = useDisplayUnit();
  return (
    <SimpleBarChart
      primaryXAxisLabel={unit}
      customTooltipContent={(series, dataPointIndex) => (
        <KeyValueTooltip
          displayUnit={displayUnit}
          label={totalCategories[dataPointIndex]}
          value={series[0][dataPointIndex]}
          unit={unit}
        />
      )}
      loading={loading}
      vertical
      primaryTitleOffsetY={400}
      height={450}
      categories={totalCategories}
      series={totalSeries}
    />
  );
}

TotalPerDrillingActivityChart.propTypes = {
  unit: PropTypes.string,
  totalSeries: PropTypes.arrayOf(PropTypes.object),
  totalCategories: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool
};

TotalPerDrillingActivityChart.defaultProps = {
  unit: '',
  totalSeries: [],
  totalCategories: [],
  loading: false
};

export default TotalPerDrillingActivityChart;

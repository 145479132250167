import React, { useState } from 'react';
import { Button, FeedOutlinedIcon, SettingsOutlinedIcon, Stack } from '@esgian/esgianui';
import BaselineChartSettingModal from '@components/Modals/BaselineChartSettingModal/BaselineChartSettingModal';
import ExportBaselinePdfModal from '@components/Modals/ExportBaselinePdfModal';

function BaselineCompActionButtons() {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  return (
    <Stack direction={'row'} spacing={1}>
      <Button
        variant={'text'}
        color={'secondary'}
        sx={{ color: (theme) => theme.palette.secondary.contrastText }}
        onClick={() => {
          setSettingsModalOpen(true);
        }}
        startIcon={<SettingsOutlinedIcon fontSize={'small'} color={'inherit'} />}>
        Chart Settings
      </Button>
      <Button
        variant={'text'}
        color={'secondary'}
        sx={{ color: (theme) => theme.palette.secondary.contrastText }}
        onClick={() => {
          setExportModalOpen(true);
        }}
        startIcon={<FeedOutlinedIcon color={'inherit'} />}>
        report
      </Button>
      {settingsModalOpen && (
        <BaselineChartSettingModal handleClose={() => setSettingsModalOpen(false)} />
      )}
      {exportModalOpen && <ExportBaselinePdfModal handleClose={() => setExportModalOpen(false)} />}
    </Stack>
  );
}

BaselineCompActionButtons.propTypes = {};

BaselineCompActionButtons.defaultProps = {};

export default BaselineCompActionButtons;

import React, { useMemo, useState } from 'react';
import { Grid, Paper, Typography } from '@esgian/esgianui';
import { DrillingActivitySummary } from '@components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { PeriodicOverviewWithDetailsChart } from '@components/Charts';
import { useDisplayUnit, useSeriesBuilder } from '@hooks';
import { TIMESTAMP, UTC_TIMESTAMP, DATE_FORMAT } from '@constants';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';
import { getValue } from '@helpers';

const getSubtitle = (unit) => {
  switch (unit) {
    case 'Energy':
      return (
        <Typography variant="body2">
          Detailed drilling equipment energy consumption in selection
        </Typography>
      );
    case 'CO2':
      return (
        <Typography variant="body2">
          Detailed drilling equipment CO<sub>2</sub>e emissions in selection
        </Typography>
      );
    case 'Fuel':
      return (
        <Typography variant="body2">
          Detailed drilling equipment fuel consumption in selection
        </Typography>
      );
    case 'NOx':
      return (
        <Typography variant="body2">
          Detailed drilling equipment NO<sub>x</sub> emissions in selection
        </Typography>
      );
  }
};

const legendsColorMapping = {
  Cementing: '#E7C014',
  'Condition mud & circulating': '#20AFFF',
  'Pressure Test Hole': '#E69700',
  'Run casing': '#4ED5FF',
  Tripping: '#017CFF',
  'Nipple B.O.P.': '#E68600',
  Safety: '#555555',
  'Service Rig': '#9D9D9D',
  'Well Control': '#E4B600',
  Drilling: '#1557FF',
  Completion: '#4EFFFF',
  'Test Well Control Equipment': '#EACC45',
  'Wireline, Coiled Tubing & Logging': '#E5A900',
  Reaming: '#BAB1F0',
  Coring: '#A7C0FF',
  'Mechanical Plug & Whipstock': '#C4D4FF',
  'Non Productive Time': '#434343',
  'Rig Move Jack Up': '#7B7B7B',
  'Rig Downtime': '#C4C4C4',
  Abandonment: '#D9D9D9',
  'Rig off contract': '#C7DEC8',
  Other: '#8ECE90',
  'Activity data missing': '#FF495C',
  Standby: '#C7DEC8'
};

const orderMap = {
  drilling: 0,
  tripping: 1,
  'condition mud & circulating': 2,
  'run casing': 3,
  completion: 4,
  reaming: 5,
  coring: 6,
  'mechanical plug & whipstock': 7,
  'nipple b.o.p.': 8,
  'pressure test hole': 9,
  'wireline, coiled tubing & logging': 10,
  'well control': 11,
  cementing: 12,
  'test well control equipment': 13,
  'non productive time': 14,
  safety: 15,
  'rig move jack up': 16,
  'service rig': 17,
  'rig downtime': 18,
  abandonment: 19,
  standby: 20,
  'rig off contract': 21,
  other: 22,
  'activity data missing': 23
};
/**
 * Function that converts the api data into a day/hour rollup.
 * */
const sumDataByUnit = (data, viewByUnit, displayUnit, selectedTimeZone, start, end) => {
  let activities = [];
  let cat = [];
  let dataTimes = {};
  let startDate = moment(start);
  let endDate = moment(end);
  while (startDate.isSameOrBefore(endDate)) {
    let dayKey = startDate.clone().format(UTC_TIMESTAMP);
    cat.push(dayKey);
    dataTimes[dayKey] = 0;
    startDate.add(1, viewByUnit);
  }

  data.forEach(({ activity, drillingActivityEvents }) => {
    let data = { ...dataTimes };

    Object.entries(drillingActivityEvents[`drilling${displayUnit}`]).forEach(([key, value]) => {
      let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
      if (data[dayKey] !== undefined) {
        data[dayKey] += getValue(value);
      }
    });
    let tempName = activity;
    if (tempName === 'Uncategorized') {
      tempName = 'Activity data missing';
    }

    activities.push({ activity: tempName, data: data });
  });

  return { data: activities, categories: cat };
};

function DrillingActivitiesSection({ displayUnit }) {
  const [logInfo, setLogInfo] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const { getUnitTitle } = useDisplayUnit();
  const { getCanvasTimeSeriesData } = useSeriesBuilder();
  const { startDate, endDate } = useSelector(getMainPageSlice);
  const { rigDrillingActivityQuery } = useMainPageQueries();
  const { selectedTimeZone } = useTimezone();

  const getSeriesAndUnit = (data) => {
    if (!data?.length) return {};
    const maxValue = Math.max(...data.flatMap((obj) => Object.values(obj.data)));
    const { converted, title } = getUnitTitle(maxValue);
    const series = data
      .map(({ activity, data }) => {
        const { defaultArray, convertedArray } = getCanvasTimeSeriesData(data, displayUnit);
        return {
          type: 'bar',
          backgroundColor: legendsColorMapping[activity || 'Other'],
          stack: 1,
          borderSkipped: true,
          label: activity,
          data: converted ? convertedArray : defaultArray
        };
      })
      .sort((a, b) => {
        const indexA = orderMap[a.label.toLowerCase()];
        const indexB = orderMap[b.label.toLowerCase()];
        return indexA - indexB;
      });

    return { series, unit: title };
  };

  const { primarySeries = [], primUnit = '' } = useMemo(() => {
    if (!rigDrillingActivityQuery.data) return {};
    const { data, categories } = sumDataByUnit(
      rigDrillingActivityQuery.data,
      'day',
      displayUnit,
      selectedTimeZone,
      moment(startDate).format(DATE_FORMAT),
      moment(endDate).format(DATE_FORMAT)
    );

    const { series, unit } = getSeriesAndUnit(data);
    // if (!selectedColumn) {
    setSelectedColumn({
      date: moment.utc(categories[0]).format(UTC_TIMESTAMP),
      index: 0,
      isLast: false
    });
    // }
    return { primarySeries: series, primUnit: unit };
  }, [rigDrillingActivityQuery.data, displayUnit]);

  const { secondarySeries = [], secondaryUnit = '' } = useMemo(() => {
    if (!selectedColumn) return {};
    let sDate = moment(selectedColumn.date).startOf('day');
    let eDate = moment.tz(selectedColumn.date, selectedTimeZone).endOf('day');

    if (selectedColumn.index >= 1) {
      sDate = sDate.subtract(1, 'days');
    }
    if (!selectedColumn.isLast) {
      eDate = eDate.add(1, 'days');
    }
    if (eDate.isAfter(moment.tz(selectedTimeZone.date))) {
      eDate = moment.tz(selectedTimeZone.date);
    }
    eDate = moment(eDate.format(TIMESTAMP));

    const { data, categories } = sumDataByUnit(
      rigDrillingActivityQuery.data,
      'hour',
      displayUnit,
      selectedTimeZone,
      sDate,
      eDate
    );
    let filteredData = data.filter((item) => {
      return !Object.values(item.data).every((value) => value === 0);
    });
    const { series: series, unit } = getSeriesAndUnit(filteredData);
    return { secondarySeries: series, secondaryUnit: unit, secondaryCategories: categories };
  }, [rigDrillingActivityQuery.data, displayUnit, selectedColumn]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3.5}>
        <DrillingActivitySummary
          drillingData={rigDrillingActivityQuery.data}
          loading={rigDrillingActivityQuery.isFetching}
        />
      </Grid>
      <Grid item xs={8.5}>
        <Paper sx={{ p: 2 }}>
          <PeriodicOverviewWithDetailsChart
            secondaryStacked
            enableBarHover
            disableHoverLinePlugin
            secondaryUnit={secondaryUnit}
            secondarySeries={secondarySeries}
            secondaryChartStacked
            primaryUnit={primUnit}
            loading={rigDrillingActivityQuery.isFetching}
            selectedView={'hour'}
            secondaryChartType={'bar'}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            handleLogClick={setLogInfo}
            primarySeries={primarySeries}
            detailsTitle="Activity details"
            detailsTitleTooltip={`Drilling equipment consumption or emissions per day for each activity in the selected time period.
              Clicking on one of the bars will reveal the details per hour for the day before, selected day and the next day in the Activity details graph below.`}
            subTitle={getSubtitle(displayUnit)}
            title="Activity overview"
            titleTooltipText={`Drilling equipment consumption or emissions per day for each activity in the selected time period.
Clicking on one of the bars will reveal the details per hour for the day before, selected day and the next day in the Activity details graph below.`}
          />
          <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
        </Paper>
      </Grid>
    </Grid>
  );
}

DrillingActivitiesSection.propTypes = {
  displayUnit: PropTypes.string
};

DrillingActivitiesSection.defaultProps = {};

export default DrillingActivitiesSection;

import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField, Typography } from '@esgian/esgianui';
import { VIEW_BY_TYPE } from '@constants';

function HourDaySelect({ disabled, handleChange, value }) {
  return (
    <TextField
      select
      disabled={disabled}
      label={'View'}
      variant={'outlined'}
      inputProps={{ sx: { width: '150px', p: 1, placeContent: 'center' } }}
      value={value || ''}
      onChange={({ target }) => handleChange(parseInt(target.value))}>
      <MenuItem value={VIEW_BY_TYPE.DAILY}>
        <Typography variant={'body2'}>Daily</Typography>
      </MenuItem>
      <MenuItem value={VIEW_BY_TYPE.HOURLY}>
        <Typography variant={'body2'}>Hourly</Typography>
      </MenuItem>
    </TextField>
  );
}

HourDaySelect.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(VIEW_BY_TYPE))
};

HourDaySelect.defaultProps = { disabled: false, value: VIEW_BY_TYPE.DAILY };

export default HourDaySelect;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRigsOverview, getUserBaselines } from '@api/Lookups';

const STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

const initialState = {
  statusLookup: STATUSES.IDLE,
  rigs: [],
  baselines: []
};

function sortBaselines(a, b) {
  if (a.rank < b.rank) {
    return -1;
  }
  if (a.rank > b.rank) {
    return 1;
  }
  return 0;
}

export const fetchLookupData = createAsyncThunk(
  'data/fetchLookupData',
  async ({ token, userId }) => {
    let responses = [];
    try {
      responses = await Promise.all([
        getRigsOverview(token).catch(() => []),
        getUserBaselines(token, userId).catch(() => [])
      ]);
    } catch (error) {
      console.log(error);
    }
    return { rigs: responses[0] || [], baselines: responses[1] || [] };
  }
);

const DataSlice = createSlice({
  name: 'Data',
  initialState: initialState,
  reducers: {
    addBaseline: (state, action) => {
      state.baselines.push(action.payload);
    },
    removeBaseline: (state, action) => {
      state.baselines = state.baselines.filter(
        ({ baselineId }) => baselineId !== action.payload.baselineId
      );
    },
    updateBaselineDetails: (state, action) => {
      let idx = state.baselines.findIndex(
        ({ baselineId }) => baselineId === action.payload.baselineId
      );
      if (idx >= 0) {
        state.baselines[idx] = action.payload;
      }
      state.baselines.sort((a, b) => a.rank - b.rank);
    },
    setBaselineArray: (state, action) => {
      state.baselines = action.payload.sort((a, b) => a.rank - b.rank);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLookupData.pending, (state) => {
        state.statusLookup = STATUSES.LOADING;
      })
      .addCase(fetchLookupData.fulfilled, (state, action) => {
        state.statusLookup = STATUSES.SUCCEEDED;
        state.rigs = action.payload.rigs;
        state.baselines = action.payload.baselines.sort(sortBaselines);
      })
      .addCase(fetchLookupData.rejected, (state) => {
        state.statusLookup = STATUSES.FAILED;
      });
  }
});

export const getLookupRigs = (state) => state.data.rigs;
export const getMyBaselines = (state) => state.data.baselines;
export const getNextRankNumber = (state) => {
  let baselines = state.data.baselines;
  if (!baselines.length) {
    return 1;
  }
  return baselines[baselines.length - 1].rank + 1;
};

export const getLookupIsLoading = (state) =>
  [STATUSES.LOADING, STATUSES.IDLE].includes(state.data.statusLookup);

export const { setBaselineArray, removeBaseline, addBaseline, updateBaselineDetails } =
  DataSlice.actions;

export default DataSlice.reducer;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SimpleBarChart } from '@components/Charts';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { getValue, parseNumberToSignificant } from '@helpers';
import { useDisplayUnit } from '@hooks';
import { DISPLAY_UNIT } from '@constants';
import { Paper, Stack, Typography } from '@esgian/esgianui';

function TotRegeneratedEnergyDrawworks({ equipmentData, loading }) {
  const { getUnitTitle, displayUnit, convertUnitValue } = useDisplayUnit();
  const { series = [], unit = '' } = useMemo(() => {
    if (!equipmentData) return {};
    const { clarifyData } = equipmentData;
    const { DC_Break_Res_Energy, VFD_Reused_Energy } = clarifyData;
    let reused = Object.values(VFD_Reused_Energy).reduce((a, b) => a + getValue(b), 0);
    let burned = Object.values(DC_Break_Res_Energy).reduce((a, b) => a + getValue(b), 0);
    let max = reused > burned ? reused : burned;
    let displayUnit = DISPLAY_UNIT.ENERGY;
    const { title, converted } = getUnitTitle(max, displayUnit);
    reused = converted ? convertUnitValue(reused, displayUnit) : parseNumberToSignificant(reused);
    burned = converted ? convertUnitValue(burned, displayUnit) : parseNumberToSignificant(burned);
    return { series: [{ name: 'Regenerated Energy', data: [reused, burned] }], unit: title };
  }, [equipmentData]);
  const categories = useMemo(() => ['Reused', 'Burned'], []);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={1}>
        <Typography variant={'h6'}>Regenerated Energy from Drawworks</Typography>
        <SimpleBarChart
          legendOffsetX={-35}
          legendOffsetY={-10}
          primaryTitleOffsetX={unit.length >= 3 ? 0 : 40}
          height={295}
          primaryXAxisLabel={unit}
          primaryTitleOffsetY={0}
          loading={loading}
          categories={categories}
          series={series}
          customTooltipContent={(series, dataPointIndex) => (
            <KeyValueTooltip
              displayUnit={displayUnit}
              label={categories[dataPointIndex]}
              value={series[0][dataPointIndex]}
              unit={unit}
            />
          )}
        />
      </Stack>
    </Paper>
  );
}

TotRegeneratedEnergyDrawworks.propTypes = {
  equipmentData: PropTypes.object,
  loading: PropTypes.bool
};

TotRegeneratedEnergyDrawworks.defaultProps = { equipmentData: null, loading: false };

export default TotRegeneratedEnergyDrawworks;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowBackIcon, Box, Stack, Typography } from '@esgian/esgianui';
import { ArrowBackButton } from '@components/Buttons';
import UnitDisplaySelect from '@components/Inputs/UnitDisplaySelect';
import { useSelector } from 'react-redux';
import { getSideMenuOpen } from '@store/features';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getSelectedBaseline } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DATE_MONTH_READABLE_FORMAT } from '@constants';
import PageActionButtons from '../../components/Menues/PageActionButtons';

function GreenpactLiveStickyHeader(props) {
  const [isSticky, setIsSticky] = useState(false);
  const sideMenuOpen = useSelector(getSideMenuOpen);
  const { selectedRig, startDate, endDate } = useSelector(getMainPageSlice);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 140) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let sideMenuSize = sideMenuOpen ? 264 : 56;

  const getDatesReadable = (start, end) => {
    return `${moment.utc(start).format(DATE_MONTH_READABLE_FORMAT)} - ${moment
      .utc(end)
      .format(DATE_MONTH_READABLE_FORMAT)}`;
  };
  return (
    <Box
      id={'header-sticky'}
      sx={{
        width: `calc(100% - ${sideMenuSize}px)`,
        position: 'fixed',
        top: 0,
        left: `${sideMenuSize}px)`,
        right: 0,
        backgroundColor: ({ palette }) => palette.background.default,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease-in-out',
        borderBottom: ({ palette }) => `1px solid ${palette.neutral.neutral01}`,
        transform: `translateY(${isSticky ? 0 : '-100%'})`,
        zIndex: 100
      }}>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <Stack sx={{ p: 2, alignItems: 'center' }} spacing={5} direction={'row'}>
          <Stack spacing={2} direction={'row'} sx={{ alignItems: 'center' }}>
            <ArrowBackButton onClick={() => navigate('/gpr-live')}>
              <ArrowBackIcon color={'inherit'} fontSize={'small'} />
            </ArrowBackButton>
            <Typography
              variant={'body1'}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}>
              {`${selectedRig?.rigName}, ${getDatesReadable(startDate, endDate)}`}
            </Typography>
          </Stack>
          <UnitDisplaySelect />
        </Stack>
        <PageActionButtons />
      </Stack>
    </Box>
  );
}

GreenpactLiveStickyHeader.propTypes = {};

GreenpactLiveStickyHeader.defaultProps = {};

export default GreenpactLiveStickyHeader;

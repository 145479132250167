import React, { useState, useMemo, useEffect } from 'react';
import {
  Button,
  FeedOutlinedIcon,
  SettingsOutlinedIcon,
  Stack,
  Typography
} from '@esgian/esgianui';
import { createRigFuelPrice, deleteFuelPrice, updateRigFuelPrice } from '@api/Settings';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { useTheme } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ExportPdfModal, RigSettingsModal } from '@components/Modals';
import { TIME_ZONE } from '@constants';
import {
  getMainPageRig,
  setTimeZone,
  setCustomUTC,
  getCustomUTC,
  getTimeZone,
  getRigUTC
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';

function PageActionButtons() {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedRig = useSelector(getMainPageRig);
  const selectedUTC = useSelector(getCustomUTC);
  const timeZone = useSelector(getTimeZone);
  const rigUTC = useSelector(getRigUTC);
  const { theme } = useTheme();
  const { resetMainPageData } = useMainPageQueries();

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const handleSaveSettings = async (
    deleteItems,
    tempFuelPrices,
    fuelPrices,
    selectedTimeZone,
    selectedCustomUTC
  ) => {
    Swal.fire({
      title: 'Saving settings...',
      background: theme.palette.neutral.neutral01,
      color: theme.palette.text.primary
    });
    Swal.showLoading();

    dispatch(setTimeZone(selectedTimeZone));
    dispatch(setCustomUTC(selectedCustomUTC));

    const controller = new AbortController();
    const { signal } = controller;
    setSettingsModalOpen(false);
    let apiCalls = [];
    deleteItems.forEach((item) => {
      apiCalls.push(deleteFuelPrice(signal, selectedRig, item));
    });
    let newItemsCount = 0;
    tempFuelPrices.forEach((item, i) => {
      if (item.isNew) {
        newItemsCount++;
      }
      if (item.isNew && item.price && moment(item.startDate).isValid()) {
        apiCalls.push(
          createRigFuelPrice(signal, selectedRig, { ...item, rigId: selectedRig.rigId })
        );
      }
      if (!item.isNew && item.price !== fuelPrices[i - newItemsCount].price) {
        apiCalls.push(updateRigFuelPrice(signal, selectedRig, item));
      }
    });
    if (apiCalls.length === 0) {
      Swal.close();
    }
    try {
      await Promise.all(apiCalls)
        .then(() => {
          Swal.close();
          resetMainPageData();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Settings saved',
            background: theme.palette.neutral.neutral01,
            color: theme.palette.text.primary,
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(() => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            background: theme.palette.neutral.neutral01,
            color: theme.palette.text.primary,
            title: 'Unable to save settings',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            setSettingsModalOpen(true);
          });
        });
    } catch (e) {
      setSettingsModalOpen(true);
    }
  };

  const selectedCustomUTC = useMemo(() => {
    // if costum time zone is set
    if (timeZone === TIME_ZONE.CUSTOM_TIME_ZONE && selectedUTC?.value) {
      const timezone = selectedUTC?.value || null;
      const momentCustomDateTime = moment().tz(timezone);
      const formattedCustomTime = momentCustomDateTime.format('YYYY-MM-DD HH:mm (UTCZ)');
      return `CUSTOM TIME ZONE: ${formattedCustomTime}`;
    }
    // if costum time zone is NOT set - time is displayed based on rigName selection
    if (timeZone === TIME_ZONE.RIGS_LOCATION || !selectedUTC?.value) {
      const momentDefaultDateTime = moment().tz(rigUTC || 'Europe/Oslo');
      const formattedDefaultTime = momentDefaultDateTime.format('YYYY-MM-DD HH:mm (UTCZ)');
      return `CURRENT RIG TIME: ${formattedDefaultTime}`;
    }
    return '';
  }, [currentDate, selectedUTC, rigUTC]);

  return (
    <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
      <Typography sx={{ padding: '8px 16px', fontSize: '14px' }}>{selectedCustomUTC}</Typography>
      <Button
        variant={'text'}
        color={'secondary'}
        sx={{ color: (theme) => theme.palette.secondary.contrastText }}
        onClick={() => {
          setSettingsModalOpen(true);
        }}
        startIcon={<SettingsOutlinedIcon color={'inherit'} />}>
        Settings
      </Button>
      {/*<Button*/}
      {/*  variant={'text'}*/}
      {/*  color={'secondary'}*/}
      {/*  sx={{ color: (theme) => theme.palette.secondary.contrastText }}*/}
      {/*  onClick={() => {}}*/}
      {/*  startIcon={<HelpOutlineIcon color={'inherit'} />}>*/}
      {/*  Help*/}
      {/*</Button>*/}
      <Button
        variant={'text'}
        color={'secondary'}
        sx={{ color: (theme) => theme.palette.secondary.contrastText }}
        onClick={() => {
          setExportModalOpen(true);
        }}
        startIcon={<FeedOutlinedIcon color={'inherit'} />}>
        report
      </Button>
      {settingsModalOpen && (
        <RigSettingsModal
          handleSave={handleSaveSettings}
          handleClose={() => {
            setSettingsModalOpen(false);
          }}
        />
      )}
      {exportModalOpen && (
        <ExportPdfModal
          handleClose={() => {
            setExportModalOpen(false);
          }}
        />
      )}
    </Stack>
  );
}

PageActionButtons.propTypes = {};

PageActionButtons.defaultProps = {};

export default PageActionButtons;

import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Typography } from '@esgian/esgianui';

function BaselineTypeLabel({ baselineTypeId }) {
  return (
    <Chip
      size={'small'}
      label={
        <Typography sx={{ fontSize: '10px' }} variant={'caption'}>
          {baselineTypeId === 1 ? 'Time/Well' : 'Target'}
        </Typography>
      }
      sx={{
        height: '20px',
        color: ({ palette }) => `${palette.common.white}`,
        backgroundColor: ({ palette }) =>
          `${baselineTypeId === 1 ? palette.primary.main : palette.info.main}80`
      }}
    />
  );
}

BaselineTypeLabel.propTypes = {
  baselineTypeId: PropTypes.oneOf([1, 2])
};

BaselineTypeLabel.defaultProps = {
  baselineTypeId: 1
};

export default BaselineTypeLabel;

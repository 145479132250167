import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Stack, Typography } from '@esgian/esgianui';
import CustomTooltip from '@components/Charts/BarChartWithCustomTooltip/CustomTooltip/CustomTooltip';
import { parseNumberToSignificant } from '@helpers';

function PeriodicOverviewTooltip({
  id,
  unit,
  date,
  handleLogClick,
  position,
  column,
  series,
  showSum,
  legendColors,
  setShowTooltip
}) {
  useEffect(() => {
    // Remove the tooltip when user is not hovering the chart
    let tooltip = document.getElementById(id);
    tooltip?.addEventListener('mouseleave', () => {
      setShowTooltip(null);
    });
  }, []);

  const hasActivities = useMemo(() => {
    let hasValue = false;
    series?.forEach(({ data }) => {
      if ((data[column]?.length && data[column][1] > 0) || data[column] > 0) {
        hasValue = true;
      }
    });
    return hasValue;
  }, [series, column]);
  return (
    <CustomTooltip id={id} position={position}>
      <Stack sx={{ pt: 1, pb: 1, pr: 2, pl: 2 }} spacing={1}>
        <Typography variant={'subtitle1'} bold>
          {date}
        </Typography>
        {showSum && (
          <>
            <Stack spacing={1} justifyContent={'space-between'} direction={'row'}>
              <Typography variant={'body2'}>Sum: </Typography>
              <Typography variant={'body2'}>
                {120} {unit}
              </Typography>
            </Stack>
            <Box sx={{ width: '100%' }}>
              <Divider sx={{ borderColor: ({ palette }) => palette.tooltip.divider }} />
            </Box>
          </>
        )}
        <Stack spacing={0.5}>
          {hasActivities &&
            series?.map(({ name, data }, i) => {
              if (Array.isArray(data[column]) ? !data[column]?.length : !data[column])
                return <React.Fragment key={`${i}-empty`}></React.Fragment>;
              return (
                <Stack
                  key={`${i}-legend-val`}
                  spacing={1}
                  justifyContent={'space-between'}
                  direction={'row'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <div
                      style={{
                        marginBottom: '2px',
                        height: '10px',
                        width: '10px',
                        background: legendColors[i]
                      }}
                    />
                    <Typography variant={'body2'}>{name}</Typography>
                  </Stack>
                  <Typography variant={'body2'}>
                    {data[column]
                      ? `${parseNumberToSignificant(
                          Array.isArray(data[column]) ? data[column][1] || 0 : data[column] || 0
                        )} ${name === 'Engine online' ? '' : unit}`
                      : `0 ${unit}`}
                  </Typography>
                </Stack>
              );
            })}
          {!hasActivities && <Typography variant={'body2'}>No activities</Typography>}
        </Stack>
        {hasActivities && handleLogClick && (
          <Box sx={{ p: 1, alignSelf: 'center' }}>
            <Button
              onClick={() => handleLogClick(column)}
              sx={{
                color: ({ palette }) => palette.tooltip.contrastText,
                borderColor: ({ palette }) => palette.tooltip.contrastText
              }}
              color={'secondary'}
              size={'medium'}
              variant={'outlined'}>
              See log
            </Button>
          </Box>
        )}
      </Stack>
    </CustomTooltip>
  );
}

PeriodicOverviewTooltip.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number
  }),
  handleLogClick: PropTypes.func,
  setShowTooltip: PropTypes.func.isRequired,
  column: PropTypes.number,
  series: PropTypes.arrayOf(PropTypes.object),
  date: PropTypes.string,
  id: PropTypes.string,
  unit: PropTypes.string,
  showSum: PropTypes.bool,
  legendColors: PropTypes.arrayOf(PropTypes.string)
};

PeriodicOverviewTooltip.defaultProps = {
  position: {
    top: 0,
    left: 0
  },
  column: null,
  handleLogClick: undefined,
  showSum: false,
  series: [],
  date: null,
  unit: 'MWh',
  id: 'po-custom-tooltip',
  legendColors: []
};

export default PeriodicOverviewTooltip;

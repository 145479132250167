import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { SimpleBarChart } from '@components/Charts';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { useSeriesBuilder } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { DISPLAY_UNIT } from '@constants';
import { TextWithTooltipIcon } from '@components';

function TotalForPeriod({ equipmentData, loading }) {
  const { getEquipmentDataSumSeries } = useSeriesBuilder();
  const displayUnit = useSelector(getDisplayUnit);

  const { series = [], unit = '' } = useMemo(() => {
    if (!equipmentData) return {};
    const { clarifyData } = equipmentData;
    const { title, mp, hpu, dw, td } = getEquipmentDataSumSeries(clarifyData, displayUnit);
    return {
      series: [{ name: 'Equipment consumed energy', data: [mp, hpu, dw, td] }],
      unit: title
    };
  }, [displayUnit, equipmentData]);

  const categories = useMemo(() => ['MP', 'HPU', 'DW', 'TD'], []);

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Total equipment CO2e emissions in period';
      case DISPLAY_UNIT.NOX:
        return 'Total equipment NOx emissions in period';
      case DISPLAY_UNIT.FUEL:
        return 'Total equipment fuel consumption in period';
      case DISPLAY_UNIT.ENERGY:
        return 'Total equipment energy consumption in period';
    }
  }, [displayUnit]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={1}>
        <TextWithTooltipIcon
          tooltipText={`Total consumption or emissions for each equipment in the selected time period.`}
          label={<Typography variant={'h6'}>Equipment summary</Typography>}
        />
        <Typography variant="body2">{getSubTitle}</Typography>
        <SimpleBarChart
          primaryTitleOffsetX={unit.length >= 3 ? 0 : 10}
          height={360}
          primaryXAxisLabel={unit}
          primaryTitleOffsetY={0}
          loading={loading}
          categories={categories}
          series={series}
          customTooltipContent={(series, dataPointIndex) => (
            <KeyValueTooltip
              displayUnit={displayUnit}
              label={categories[dataPointIndex]}
              value={series[0][dataPointIndex]}
              unit={unit}
            />
          )}
        />
      </Stack>
    </Paper>
  );
}

TotalForPeriod.propTypes = {
  equipmentData: PropTypes.object,
  loading: PropTypes.bool
};

TotalForPeriod.defaultProps = {
  equipmentData: null,
  loading: false
};

export default TotalForPeriod;

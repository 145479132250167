import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { LoadingScreen } from '@components';
import { useSelector } from 'react-redux';
import { getUser, isUserLoading, userAccess } from '@store/features';

function PrivateRoute({ children }) {
  const user = useSelector(getUser);
  const loadingUser = useSelector(isUserLoading);
  const userHasAccess = useSelector(userAccess);

  if (loadingUser && !user) {
    return <LoadingScreen />;
  }
  if (userHasAccess) {
    return children;
  }
  return <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

PrivateRoute.defaultProps = {};

export default PrivateRoute;

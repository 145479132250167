import { createSlice } from '@reduxjs/toolkit';
import {
  APP_NAME,
  BASELINE_CHART_TYPE,
  DATA_TYPE,
  DATE_FORMAT,
  DISPLAY_PERIOD,
  SFOC_OR_LOAD,
  STORE_VERSION,
  VIEW_BY_TYPE
} from '@constants';
import {
  LOOKUP_DRILLING_ACTIVITY,
  LOOKUP_EQUIPMENT,
  LOOKUP_FOCUS_AREA,
  LOOKUP_OPERATIONS
} from '../../../../lookups';
import moment from 'moment-timezone';

const initialState = {
  selectedBaseline: null,
  ignoreDefaults: false,
  hasApiSettings: false,
  defaultDataType: DATA_TYPE.ACCUMULATED,
  defaultChartType: BASELINE_CHART_TYPE.BY_TIME,
  graphDisplays: [
    { graphName: 'Periodic overview', rank: 1, visible: true },
    { graphName: 'Drilling activities', rank: 2, visible: true },
    { graphName: 'Equipment', rank: 3, visible: true },
    { graphName: 'Power mgmt SFOC', rank: 4, visible: true },
    { graphName: 'Analytics', rank: 5, visible: true }
  ],
  keyFigures: {
    displayPeriod: DISPLAY_PERIOD.ALL_TIME
  },
  drillingActivities: {
    displayPeriod: DISPLAY_PERIOD.ALL_TIME,
    activityType: LOOKUP_DRILLING_ACTIVITY,
    chartType: BASELINE_CHART_TYPE.BY_TIME,
    dataType: DATA_TYPE.ACCUMULATED,
    viewBy: VIEW_BY_TYPE.DAILY
  },
  equipment: {
    displayPeriod: DISPLAY_PERIOD.ALL_TIME,
    equipmentType: LOOKUP_EQUIPMENT,
    chartType: BASELINE_CHART_TYPE.BY_TIME,
    dataType: DATA_TYPE.ACCUMULATED,
    viewBy: VIEW_BY_TYPE.DAILY
  },
  analytics: {
    displayPeriod: DISPLAY_PERIOD.ALL_TIME,
    focusArea: LOOKUP_FOCUS_AREA,
    chartType: BASELINE_CHART_TYPE.BY_TIME,
    dataType: DATA_TYPE.ACCUMULATED,
    viewBy: VIEW_BY_TYPE.DAILY
  },
  powerMgmt: {
    displayPeriod: DISPLAY_PERIOD.ALL_TIME,
    dataUnit: SFOC_OR_LOAD.SFOC,
    chartType: BASELINE_CHART_TYPE.BY_TIME,
    dataType: DATA_TYPE.DISCRETE,
    viewBy: VIEW_BY_TYPE.DAILY
  },
  periodicOverview: {
    displayPeriod: DISPLAY_PERIOD.ALL_TIME,
    operation: LOOKUP_OPERATIONS,
    chartType: BASELINE_CHART_TYPE.BY_TIME,
    dataType: DATA_TYPE.ACCUMULATED,
    viewBy: VIEW_BY_TYPE.DAILY
  }
};

const getSessionState = () => {
  try {
    const serializedState = sessionStorage.getItem(APP_NAME);
    if (!serializedState) return undefined;
    const stateObj = JSON.parse(serializedState);
    if (stateObj.version !== STORE_VERSION) {
      return undefined;
    }
    return stateObj.filters.baselineComparison;
  } catch (e) {
    return undefined;
  }
};

const BaselineComparisonSlice = createSlice({
  name: 'BaselineComparisonSection',
  initialState: getSessionState() ?? initialState,
  reducers: {
    resetBaselineSlice: () => initialState,
    setSelectedBaseline: (state, action) => {
      state.selectedBaseline = action.payload;
    },
    setBaselineViewSettings: (state, action) => {
      const { payload } = action;
      const defaultDataType = parseInt(payload.defaultDataType);
      const defaultChartType = parseInt(payload.defaultChartType);
      if (state.defaultChartType !== defaultChartType) {
        state.defaultChartType = defaultChartType;
        if (!payload.ignoreDefaults) {
          state.drillingActivities.chartType = defaultChartType;
          state.equipment.chartType = defaultChartType;
          state.analytics.chartType = defaultChartType;
        }
      }
      if (state.defaultDataType !== defaultDataType) {
        state.defaultDataType = defaultDataType;
        if (!payload.ignoreDefaults) {
          state.periodicOverview.dataType = defaultDataType;
          state.drillingActivities.dataType = defaultDataType;
          state.equipment.dataType = defaultDataType;
          state.analytics.dataType = defaultDataType;
        }
      }
      state.hasApiSettings = true;
      state.graphDisplays = payload.graphDisplays;
    },

    setKeyFiguresFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state.keyFigures[key] = payload[key];
      });
      state.ignoreDefaults = true;
    },
    setDrillingActivitiesFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state.drillingActivities[key] = payload[key];
      });
      state.ignoreDefaults = true;
    },
    setPeriodicOverviewFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state.periodicOverview[key] = payload[key];
      });
      state.ignoreDefaults = true;
    },
    setEquipmentFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state.equipment[key] = payload[key];
      });
      state.ignoreDefaults = true;
    },
    setPowerMgmtFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state.powerMgmt[key] = payload[key];
      });
      state.ignoreDefaults = true;
    },
    setAnalyticsFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state.analytics[key] = payload[key];
      });
      state.ignoreDefaults = true;
    }
  }
});
export const getBaselineComparisonSlice = (state) => state.filters.baselineComparison;
export const getBaselineCompKeyFigureFilters = (state) =>
  state.filters.baselineComparison.keyFigures;

export const getSelectedBaseline = (state) => state.filters.baselineComparison.selectedBaseline;
export const getBaselineDurationHours = (state) => {
  let start = state.filters.baselineComparison.selectedBaseline?.startDate;
  let end = state.filters.baselineComparison.selectedBaseline?.endDate;
  if (start && end) {
    let momentEnd = moment.utc(end, DATE_FORMAT).endOf('day');
    if (momentEnd.isAfter(moment.utc())) {
      momentEnd = moment.utc();
    }
    return Math.ceil(moment.duration(momentEnd.diff(moment.utc(start, DATE_FORMAT))).asHours());
  }
  return 0;
};

export const {
  setSelectedBaseline,
  setEquipmentFilters,
  setKeyFiguresFilters,
  resetBaselineSlice,
  setPowerMgmtFilters,
  setPeriodicOverviewFilters,
  setDrillingActivitiesFilters,
  setAnalyticsFilters,
  setBaselineViewSettings
} = BaselineComparisonSlice.actions;

export default BaselineComparisonSlice.reducer;

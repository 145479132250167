import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, MenuItem, Paper, Popover, Stack, Typography } from '@esgian/esgianui';
import NoBaselinesDisplay from '@components/Inputs/BaselineInput/NoBaselinesDisplay';
import { useDispatch } from 'react-redux';
import { setSelectedBaseline } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import BaselineTypeLabel from '@components/Inputs/BaselineInput/BaselineModal/BaselineTypeLabel';

function BaselinePopover({
  setAnchorEl,
  anchorEl,
  setModalType,
  setModalOpen,
  baselines,
  selectedBaseline
}) {
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  return (
    <Popover
      onClose={() => {
        setAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      anchorEl={anchorEl}>
      <Paper elevation={5} sx={{ width: '510px' }}>
        <Stack>
          <Stack
            sx={{ p: 2 }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography variant={'caption'} color={'text.disabled'}>
              MY BASELINE:
            </Typography>
            <Stack direction={'row'} spacing={1}>
              <Button
                onClick={() => {
                  setModalType('create');
                  setModalOpen(true);
                  setAnchorEl(null);
                }}
                size={'small'}
                variant={'text'}
                color={'primary'}>
                Create a baseline
              </Button>
              <Button
                onClick={() => {
                  setModalType('manage');
                  setModalOpen(true);
                  setAnchorEl(null);
                }}
                size={'small'}
                variant={'text'}
                color={'primary'}
                disabled={!baselines.length}>
                Manage baselines
              </Button>
            </Stack>
          </Stack>
          {!!baselines.length && (
            <Box sx={{ pt: 1, pb: 1 }}>
              {baselines.map((baseline) => {
                const { baselineName, baselineId, baselineTypeId } = baseline;
                return (
                  <MenuItem
                    sx={{
                      '&:hover': {
                        color: ({ palette }) => palette.primary.main
                      },
                      color: ({ palette }) =>
                        baselineId === selectedBaseline?.baselineId
                          ? palette.primary.main
                          : 'inherit'
                    }}
                    onClick={() => {
                      dispatch(setSelectedBaseline(baseline));
                      setAnchorEl(null);
                    }}
                    key={`baseline-select-${baselineId}`}
                    value={baselineId}>
                    <Stack
                      sx={{ width: '100%' }}
                      justifyContent={'space-between'}
                      direction={'row'}>
                      <Typography variant={'body1'}>{baselineName}</Typography>
                      <BaselineTypeLabel baselineTypeId={baselineTypeId} />
                    </Stack>
                  </MenuItem>
                );
              })}
            </Box>
          )}
          {!baselines.length && (
            <NoBaselinesDisplay
              handleCreateClick={() => {
                setModalType('create');
                setModalOpen(true);
                setAnchorEl(null);
              }}
            />
          )}
        </Stack>
      </Paper>
    </Popover>
  );
}

BaselinePopover.propTypes = {
  setAnchorEl: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  selectedBaseline: PropTypes.object,
  setModalType: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  baselines: PropTypes.arrayOf(PropTypes.object)
};

BaselinePopover.defaultProps = {
  anchorEl: null,
  selectedBaseline: null,
  baselines: []
};

export default BaselinePopover;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@esgian/esgianui';
import { BASELINE_TYPE } from '@constants';
import { TextWithTooltipIcon } from '@components';

function CreateBaselineType({ baseline, setBaseline }) {
  return (
    <Box sx={{ p: 5 }}>
      <RadioGroup
        value={baseline.baselineTypeId || null}
        onChange={({ target }) => {
          setBaseline((prev) => ({ ...prev, baselineTypeId: parseInt(target.value) }));
        }}>
        <FormControlLabel
          value={BASELINE_TYPE.TIME_WELL}
          control={<Radio size={'small'} />}
          label={<Typography variant={'body2'}>Time/Well baseline</Typography>}
        />
        <FormControlLabel
          disabled
          value={BASELINE_TYPE.TARGET}
          control={<Radio size={'small'} disabled />}
          label={
            <TextWithTooltipIcon
              tooltipText={
                <Stack>
                  <Typography bold variant={'caption'}>
                    This feature is not yet available.
                  </Typography>
                  <br />
                  <Typography variant={'caption'}>
                    Create a targeted consumption baseline for a specific time period.
                  </Typography>
                  <br />
                  <Typography variant={'caption'}>Examples: </Typography>
                  <br />
                  <Typography variant={'caption'}>
                    2000 mt fuel in total for the duration of the well you’re about to drill.{' '}
                  </Typography>
                  <Typography variant={'caption'}>
                    2000 kWh energy consumed per day for the month of September.{' '}
                  </Typography>
                </Stack>
              }
              label={'Target baseline (Coming soon)'}
              labelVariant={'body2'}
              variant={'body2'}
            />
          }
        />
      </RadioGroup>
    </Box>
  );
}

CreateBaselineType.propTypes = {
  baseline: PropTypes.object.isRequired,
  setBaseline: PropTypes.func.isRequired
};

CreateBaselineType.defaultProps = {};

export default CreateBaselineType;

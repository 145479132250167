import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { DATE_FORMAT } from '@constants';
import moment from 'moment-timezone';

function TargetDetails({ highlightErrors, baseline, setBaseline }) {
  const numDays = useMemo(() => {
    if (!baseline.startDate || !baseline.endDate) return 1;

    return moment(baseline.endDate, DATE_FORMAT).diff(
      moment(baseline.startDate, DATE_FORMAT),
      'days'
    );
  }, [baseline.startDate, baseline.endDate]);

  const handleInputChange = (val, key) => {
    const numericRegex = /^-?\d+$/;

    // Test if the string matches the regular expression
    if (numericRegex.test(val)) {
      let temp = { ...baseline };
      temp[key] = parseInt(val);
      setBaseline(temp);
    }
  };

  const getHelperText = (val) => {
    return baseline.daily
      ? `Total: ${val ? val * numDays : '-'}`
      : `Per day: ${val ? parseInt(val / numDays) : '-'}`;
  };

  return (
    <Stack spacing={1}>
      <Stack spacing={1} direction={'row'} alignItems={'center'}>
        <Typography variant={'subtitle1'} bold>
          Fill target details by:
        </Typography>
        <RadioGroup
          row
          value={baseline.daily ? 1 : 0}
          onChange={({ target }) => {
            setBaseline((prev) => ({ ...prev, daily: parseInt(target.value) === 1 }));
          }}>
          <FormControlLabel
            value={1}
            control={<Radio size={'small'} />}
            label={<Typography variant={'body2'}>Per day</Typography>}
          />
          <FormControlLabel
            value={0}
            control={<Radio size={'small'} />}
            label={<Typography variant={'body2'}>Total</Typography>}
          />
        </RadioGroup>
      </Stack>
      <Stack sx={{ pl: 3 }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} sx={{ display: 'list-item' }}>
            Fuel consumption {baseline.daily ? '(mt/day)' : '(mt)'}:
          </Typography>
          <TextField
            error={highlightErrors && !baseline.fuelConsumption}
            onChange={({ target }) => {
              handleInputChange(target.value, 'fuelConsumption');
            }}
            value={baseline?.fuelConsumption ?? ''}
            sx={{ width: '100px' }}
            inputProps={{ sx: { fontSize: '14px' } }}
            size={'small'}
            FormHelperTextProps={{ sx: { fontSize: '10px', marginTop: '0px' } }}
            variant={'standard'}
            helperText={getHelperText(baseline.fuelConsumption)}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} sx={{ display: 'list-item' }}>
            CO2 emissions {baseline.daily ? '(mt/day)' : '(mt)'}:
          </Typography>
          <TextField
            error={highlightErrors && !baseline.co2Emissions}
            onChange={({ target }) => {
              handleInputChange(target.value, 'co2Emissions');
            }}
            value={baseline?.co2Emissions ?? ''}
            sx={{ width: '100px' }}
            FormHelperTextProps={{ sx: { fontSize: '10px', marginTop: '0px' } }}
            inputProps={{ sx: { fontSize: '14px' } }}
            size={'small'}
            variant={'standard'}
            helperText={getHelperText(baseline.co2Emissions)}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} sx={{ display: 'list-item' }}>
            NOx emissions {baseline.daily ? '(kg/day)' : '(kg)'}:
          </Typography>
          <TextField
            error={highlightErrors && !baseline.noxEmissions}
            onChange={({ target }) => {
              handleInputChange(target.value, 'noxEmissions');
            }}
            value={baseline?.noxEmissions ?? ''}
            sx={{ width: '100px' }}
            FormHelperTextProps={{ sx: { fontSize: '10px', marginTop: '0px' } }}
            inputProps={{ sx: { fontSize: '14px' } }}
            size={'small'}
            variant={'standard'}
            helperText={getHelperText(baseline.noxEmissions)}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} sx={{ display: 'list-item' }}>
            Fuel waste {baseline.daily ? '(mt/day)' : '(mt)'}:
          </Typography>
          <TextField
            error={highlightErrors && !baseline.fuelWaste}
            onChange={({ target }) => {
              handleInputChange(target.value, 'fuelWaste');
            }}
            value={baseline?.fuelWaste ?? ''}
            sx={{ width: '100px' }}
            FormHelperTextProps={{ sx: { fontSize: '10px', marginTop: '0px' } }}
            inputProps={{ sx: { fontSize: '14px' } }}
            size={'small'}
            variant={'standard'}
            helperText={getHelperText(baseline.fuelWaste)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

TargetDetails.propTypes = {
  baseline: PropTypes.object.isRequired,
  setBaseline: PropTypes.func.isRequired,
  highlightErrors: PropTypes.bool
};

TargetDetails.defaultProps = {
  highlightErrors: false
};

export default TargetDetails;

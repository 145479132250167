export const numberToShortAbbreviation = (num) => {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' }
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};

export const parseNumberToSignificant = (value) => {
  if (value === null) return '-';
  let absVal = Math.abs(value);
  if (absVal > 100) {
    value = parseInt(value);
  } else if (absVal > 10) {
    value = parseFloat(value).toFixed(1);
  } else if (absVal > 1) {
    value = parseFloat(value).toFixed(2);
  } else {
    if (parseFloat(value) === 0) {
      return 0;
    }
    value = parseFloat(value).toFixed(3);
  }
  return value;
};

export const getDollarAmountReadable = (value) => {
  let tempVal = value;
  if (value === null) return '-';
  let unit = '';
  if (tempVal > 1000) {
    unit = 'k';
    tempVal = tempVal / 1000;
  } else if (tempVal > 1000000) {
    unit = 'm';
    tempVal = tempVal / 1000000;
  }
  tempVal = parseNumberToSignificant(tempVal);
  return `${tempVal}${unit}`;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  CloseIcon,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@esgian/esgianui';
import GraphDisplaySettingList from '@components/Modals/BaselineChartSettingModal/GraphDisplaySettingList';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBaselineComparisonSlice,
  setBaselineViewSettings
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { BASELINE_CHART_TYPE, DATA_TYPE } from '@constants';
import { createBaselineViewSettings, updateBaselineViewSettings } from '@api/Baselines';
import { getUser } from '@store/features';
import { toast } from 'react-toastify';

function BaselineChartSettingModal({ handleClose }) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    defaultDataType: DATA_TYPE.ACCUMULATED,
    defaultChartType: BASELINE_CHART_TYPE.BY_TIME,
    graphDisplays: []
  });
  const baselineSlice = useSelector(getBaselineComparisonSlice);
  const user = useSelector(getUser);

  useEffect(() => {
    const { defaultDataType, defaultChartType, graphDisplays } = baselineSlice;
    setFormData({
      defaultDataType: defaultDataType,
      defaultChartType: defaultChartType,
      graphDisplays: graphDisplays
    });
  }, [baselineSlice]);

  const handleSave = async () => {
    setSaving(true);
    const body = {
      defaultDataType: `${formData.defaultDataType}`,
      defaultChartType: `${formData.defaultChartType}`,
      graphDisplays: formData.graphDisplays.map((val, i) => {
        return { ...val, rank: i };
      })
    };
    let api = createBaselineViewSettings;
    if (baselineSlice.hasApiSettings) {
      api = updateBaselineViewSettings;
    }
    await api({ ...body, userId: user.id, token: user.token })
      .then(() => {
        dispatch(setBaselineViewSettings(body));
        toast.success(`Settings updated`, {
          position: 'top-right'
        });
        handleClose();
      })
      .catch(() => {
        toast.error(`Unable to updated settings`, {
          position: 'top-right'
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };
  return (
    <Modal
      DialogContentProps={{ overflow: 'hidden', borderBottom: 'none' }}
      dividers={true}
      DialogActionsProps={{ sx: { justifyContent: 'center' } }}
      actions={[
        <Button
          sx={{ color: (theme) => theme.palette.secondary.contrastText }}
          key={'cancel-button'}
          variant={'text'}
          onClick={handleClose}>
          Cancel
        </Button>,
        <Button key={'save-button'} onClick={handleSave}>
          save
        </Button>
      ]}
      closeIcon={<CloseIcon fontSize={'small'} />}
      PaperProps={{
        sx: {
          background: ({ palette }) => palette.neutral.neutral01
        }
      }}
      size={'sm'}
      open
      handleClose={handleClose}
      title={'Chart settings'}>
      <Box sx={{ p: 2, minHeight: '500px' }}>
        {saving ? (
          <Stack
            spacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ minHeight: '450px' }}>
            <CircularProgress size={'10em'} />
            <Typography variant={'h6'}>Saving...</Typography>
          </Stack>
        ) : (
          <Stack spacing={5}>
            <Stack spacing={1}>
              <Typography variant={'subtitle1'} bold>
                Default chart type
              </Typography>
              <RadioGroup
                row
                value={formData.defaultChartType}
                onChange={({ target }) => {
                  setFormData((prev) => ({ ...prev, defaultChartType: parseInt(target.value) }));
                }}>
                <FormControlLabel
                  value={BASELINE_CHART_TYPE.BY_TIME}
                  control={<Radio size={'small'} />}
                  label={<Typography variant={'body2'}>By time</Typography>}
                />
                <FormControlLabel
                  value={BASELINE_CHART_TYPE.BY_ACTIVITIES}
                  control={<Radio size={'small'} />}
                  label={<Typography variant={'body2'}>By activity</Typography>}
                />
              </RadioGroup>
            </Stack>
            <Stack spacing={1}>
              <Typography variant={'subtitle1'} bold>
                Default data type
              </Typography>
              <RadioGroup
                row
                value={formData.defaultDataType}
                onChange={({ target }) => {
                  setFormData((prev) => ({ ...prev, defaultDataType: parseInt(target.value) }));
                }}>
                <FormControlLabel
                  value={DATA_TYPE.ACCUMULATED}
                  control={<Radio size={'small'} />}
                  label={<Typography variant={'body2'}>Accumulated data</Typography>}
                />
                <FormControlLabel
                  value={DATA_TYPE.DISCRETE}
                  control={<Radio size={'small'} />}
                  label={<Typography variant={'body2'}>Discrete data</Typography>}
                />
              </RadioGroup>
            </Stack>
            <GraphDisplaySettingList
              sections={formData.graphDisplays}
              onChange={(val) => {
                setFormData((prev) => ({ ...prev, graphDisplays: val }));
              }}
            />
          </Stack>
        )}
      </Box>
    </Modal>
  );
}

BaselineChartSettingModal.propTypes = { handleClose: PropTypes.func.isRequired };

BaselineChartSettingModal.defaultProps = {};

export default BaselineChartSettingModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Skeleton, Stack, Typography } from '@esgian/esgianui';

function InfoBox({ loading, header, value }) {
  return (
    <Paper
      loading={`${loading}`}
      sx={{
        animation: loading ? 'animation-c7515d 1.5s ease-in-out 0.5s infinite' : '',
        p: '12px 16px 12px 16px',
        minHeight: '60px',
        flexGrow: 1
      }}>
      <Box sx={{ minWidth: '100%' }}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Stack sx={{ minWidth: '30%' }}>
            {typeof header === 'string' ? (
              <Typography variant={'subtitle1'}>{header}</Typography>
            ) : (
              header
            )}
            <Typography variant={'h4'} bold>
              {loading ? <Skeleton animation="wave" sx={{ width: '100%' }} /> : value}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
}

InfoBox.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string
};

InfoBox.defaultProps = {
  loading: false,
  header: '',
  value: ''
};

export default InfoBox;

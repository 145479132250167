import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import { formatNumberReadable } from '@helpers';

function PercentageLoadPerEngineTooltip({ theme, categories, series, dataPointIndex }) {
  const {
    palette: {
      tooltip: { contrastText },
      charts: { twoColorsContrast }
    }
  } = theme;
  return (
    <Stack style={{ minWidth: '200px' }} spacing={2}>
      <Typography style={{ color: contrastText, fontWeight: '600' }} bold>
        {categories[dataPointIndex]}
      </Typography>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <div style={{ flexDirection: 'row', gap: '4px', display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              height: '8px',
              width: '8px',
              background: twoColorsContrast[0]
            }}
          />
          <Typography style={{ color: contrastText }} variant={'caption'}>
            Load
          </Typography>
        </div>
        <Typography style={{ color: contrastText }} variant={'body2'}>
          {`${formatNumberReadable(series[0][dataPointIndex])} %`}
        </Typography>
      </Stack>
      {series.length > 1 && (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div style={{ flexDirection: 'row', gap: '4px', display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                height: '8px',
                width: '8px',
                background: twoColorsContrast[1]
              }}
            />
            <Typography style={{ color: contrastText }} variant={'caption'}>
              SFOC
            </Typography>
          </div>
          <Typography style={{ color: contrastText }} variant={'body2'}>
            {series[1][dataPointIndex]} g/kWh
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

PercentageLoadPerEngineTooltip.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.array),
  dataPointIndex: PropTypes.number,
  theme: PropTypes.object
};

PercentageLoadPerEngineTooltip.defaultProps = {
  categories: [],
  theme: {},
  series: [],
  dataPointIndex: -1
};

export default PercentageLoadPerEngineTooltip;

import React, { useEffect, useMemo, useState } from 'react';
import { ArrowDropDownIcon, Box, Paper, Popover, TextField } from '@esgian/esgianui';
import { DATE_FORMAT } from '@constants';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import CalendarDisplay from '@components/Inputs/DateRangePicker/CalendarDisplay';
import TimePeriodAndWellSelect from '@components/Inputs/DateRangePicker/TimePeriodAndWellSelect';
import { fetchRigWells } from '@api/DdrReport';

function DateRangePicker({
  dateFormat,
  startDate,
  endDate,
  onSave,
  fullWidth,
  withTimeWell,
  includeWellDetails,
  selectedRig,
  well,
  helperText,
  inputSize,
  inputVariant,
  error,
  maxDate,
  minStartDate
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCalendar, setShowCalendar] = useState(true);
  const [lookupWells, setLookupWells] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (withTimeWell && open) {
      // setShowCalendar(false);
    }
  }, [withTimeWell, open]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getWellData = async () => {
      try {
        await fetchRigWells(signal, {
          selectedRig: selectedRig,
          startDate: null,
          endDate: null
        }).then((result) => {
          let res = result
            .filter(({ starttime, endtime }) => {
              return moment(endtime).diff(moment(starttime), 'days') >= 3;
            })
            .sort((a, b) => {
              return (
                moment.parseZone(b.starttime).valueOf() - moment.parseZone(a.starttime).valueOf()
              );
            });
          setLookupWells(res);
        });
      } catch (error) {
        setLookupWells([]);
      }
    };
    if (withTimeWell && selectedRig) {
      // getWellData();
    }
    return () => {
      controller.abort();
    };
  }, [withTimeWell, selectedRig]);

  const subText = useMemo(() => {
    if (includeWellDetails && well) {
      return `Well: ${well.wellname}`;
    }
    if (error && helperText) {
      return helperText;
    }
    return '';
  }, [includeWellDetails, helperText, well, error]);

  return (
    <Box>
      <TextField
        variant={inputVariant}
        error={error}
        inputProps={{
          sx: { padding: inputSize === 'small' ? '' : '12px 8px', textAlign: 'start' }
        }}
        label={'Period'}
        autoComplete={'off'}
        helperText={subText}
        value={
          startDate || endDate
            ? `${startDate ? moment.parseZone(startDate).format(DATE_FORMAT) : ''} - ${
                endDate ? moment.parseZone(endDate).format(DATE_FORMAT) : ''
              }`
            : '-'
        }
        size={'small'}
        sx={{ width: fullWidth ? '100%' : '284px' }}
        InputProps={{
          sx: { cursor: 'pointer' },
          endAdornment: <ArrowDropDownIcon fontSize={'medium'} />
        }}
        type={'button'}
        onClick={({ currentTarget }) => {
          setAnchorEl(currentTarget);
        }}
      />
      <Popover
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={open}
        anchorEl={anchorEl}>
        <Paper sx={{ width: withTimeWell ? '550px' : '350px' }}>
          {showCalendar ? (
            <CalendarDisplay
              dateFormat={dateFormat}
              maxDate={maxDate}
              minStartDate={minStartDate}
              includeWellDetails={includeWellDetails}
              setAnchorEl={setAnchorEl}
              onSave={onSave}
              startDate={startDate}
              open={open}
              endDate={endDate}
            />
          ) : (
            <TimePeriodAndWellSelect
              selectedRig={selectedRig}
              includeWellDetails={includeWellDetails}
              lookupWells={lookupWells}
              startDate={startDate}
              endDate={endDate}
              setAnchorEl={setAnchorEl}
              setShowCalendar={setShowCalendar}
              onSave={onSave}
            />
          )}
        </Paper>
      </Popover>
    </Box>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string,
  fullWidth: PropTypes.bool,
  includeWellDetails: PropTypes.bool,
  withTimeWell: PropTypes.bool,
  endDate: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minStartDate: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  selectedRig: PropTypes.object,
  well: PropTypes.object,
  inputSize: PropTypes.oneOf(['small', 'medium']),
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  dateFormat: PropTypes.string
};

DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
  withTimeWell: false,
  fullWidth: false,
  maxDate: undefined,
  minStartDate: undefined,
  inputSize: 'medium',
  inputVariant: 'outlined',
  includeWellDetails: false,
  selectedRig: null,
  well: null,
  dateFormat: DATE_FORMAT,
  error: false,
  helperText: null
};

export default DateRangePicker;

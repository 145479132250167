import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, MenuItem, Stack, Tab, Tabs, Typography } from '@esgian/esgianui';
import { toast } from 'react-toastify';
import { DATE_FORMAT } from '@constants';
import moment from 'moment-timezone';
import { WellSelect } from '@components/Inputs';

const timePeriodOptions = [
  {
    title: 'Last 7 days',
    value: 1,
    amount: 7
  },
  {
    title: 'Last 30 days',
    value: 2,
    amount: 30
  },
  {
    title: 'Last 90 days',
    value: 3,
    amount: 90
  },
  {
    title: 'Last 365 days',
    value: 4,
    amount: 365
  },
  {
    title: 'Customise',
    value: 5
  }
];

function TimePeriodAndWellSelect({
  startDate,
  endDate,
  setAnchorEl,
  setShowCalendar,
  onSave,
  lookupWells,
  includeWellDetails,
  selectedRig
}) {
  const [tab, setTab] = useState(1);

  const menuItemClick = useCallback(({ target }) => {
    let value = parseInt(target.value);
    if (value === 5) {
      setShowCalendar(true);
    } else {
      onSave({
        startDate: moment()
          .startOf('day')
          .subtract(timePeriodOptions[value - 1].amount, 'days')
          .format(DATE_FORMAT),
        endDate: moment().endOf('day').format(DATE_FORMAT),
        well: null
      });
      setAnchorEl(null);
    }
  }, []);

  const selectedMenuItem = useMemo(() => {
    let selected = {};
    if (moment(endDate, DATE_FORMAT).isSame(moment(), 'day')) {
      selected =
        timePeriodOptions?.find(({ amount }) => {
          return moment().subtract(amount, 'days').isSame(moment(startDate, DATE_FORMAT), 'day');
        }) ?? {};
    }
    return selected.value ?? 5;
  }, [startDate, endDate]);

  const selectedWellItem = useMemo(() => {
    if (!lookupWells.length) return null;
    return lookupWells.find(
      ({ starttime, endtime }) =>
        moment(startDate, DATE_FORMAT).isSame(moment.utc(starttime), 'day') &&
        moment(endDate, DATE_FORMAT).isSame(moment.utc(endtime), 'day')
    );
  }, [startDate, endDate, lookupWells]);

  return (
    <Stack>
      <Typography sx={{ p: 2 }} variant={'caption'} color={'text.disabled'}>
        PERIOD SELECTION
      </Typography>
      <Tabs
        sx={{
          minHeight: 'min-content'
        }}
        variant={'fullWidth'}
        onChange={(_, val) => setTab(val)}
        value={tab}>
        <Tab
          sx={{
            '&.MuiButtonBase-root': {
              padding: '8px 16px',
              minHeight: 'fit-content'
            }
          }}
          value={1}
          label={<Typography variant={'body2'}>Time period</Typography>}
        />
        <Tab
          sx={{
            '&.MuiButtonBase-root': {
              padding: '8px 16px',
              minHeight: 'fit-content'
            }
          }}
          value={2}
          label={<Typography variant={'body2'}>Well</Typography>}
        />
      </Tabs>
      {tab === 1 && (
        <Box sx={{ pt: 0.5, pb: 0.5 }}>
          {timePeriodOptions.map(({ title, value }) => (
            <MenuItem
              selected={value === selectedMenuItem}
              sx={{
                '&:hover': {
                  color: ({ palette }) => palette.primary.main
                },
                color: ({ palette }) =>
                  value === selectedMenuItem ? palette.primary.main : 'inherit'
              }}
              onClick={menuItemClick}
              key={`time-period-${value}`}
              value={value}>
              <Typography
                variant={'body2'}
                onClick={() => menuItemClick({ target: { value: value } })}>
                {title}
              </Typography>
            </MenuItem>
          ))}
        </Box>
      )}
      {tab === 2 && (
        <Box sx={{ p: 2 }}>
          {selectedRig ? (
            <WellSelect
              onChange={(well) => {
                let result = {
                  startDate: moment.utc(well.starttime).format(DATE_FORMAT),
                  endDate: moment.utc(well.endtime).format(DATE_FORMAT)
                };
                if (includeWellDetails) {
                  result.well = well;
                }
                onSave(result);
                setAnchorEl(null);
              }}
              lookupWells={lookupWells}
              selectedWell={selectedWellItem}
            />
          ) : (
            <Alert severity={'error'}>Please select a rig first.</Alert>
          )}
        </Box>
      )}
    </Stack>
  );
}

TimePeriodAndWellSelect.propTypes = {
  setShowCalendar: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  includeWellDetails: PropTypes.bool,
  selectedRig: PropTypes.object,
  endDate: PropTypes.string,
  lookupWells: PropTypes.arrayOf(PropTypes.object)
};

TimePeriodAndWellSelect.defaultProps = {
  startDate: null,
  endDate: null,
  selectedRig: null,
  includeWellDetails: false,
  lookupWells: []
};

export default TimePeriodAndWellSelect;

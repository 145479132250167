import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getLookupRigs } from '@store/features';
import { useTheme } from '@hooks';

const sortRigs = (a, b) => {
  if (a.rigName < b.rigName) {
    return -1;
  }
  if (a.rigName > b.rigName) {
    return 1;
  }
  return 0;
};

function RigSelect({
  required,
  disabled,
  selectedRig,
  onChange,
  id,
  showTitle,
  helperText,
  error
}) {
  const [options, setOptions] = useState([]);
  const lookupRigs = useSelector(getLookupRigs);
  const { customScrollbar } = useTheme();

  useEffect(() => {
    if (!lookupRigs?.length) return;
    setOptions([...lookupRigs]?.sort(sortRigs));
  }, [lookupRigs]);

  const handleChange = useCallback(
    ({ target }) => {
      let rig = lookupRigs?.find(({ rigId }) => rigId === parseInt(target.value));
      if (!rig) return;
      onChange(rig);
    },
    [lookupRigs]
  );

  const value = useMemo(() => {
    if (!options.length) return '';

    if (typeof selectedRig === 'object') {
      return selectedRig?.rigId ?? '';
    }
    return parseInt(selectedRig) || '';
  }, [options, selectedRig]);
  return (
    <Stack id={id}>
      {showTitle && <Typography variant={'body2'}>Rig</Typography>}
      <FormControl sx={{ minWidth: 224 }} size={'small'}>
        <TextField
          helperText={helperText}
          error={error}
          InputLabelProps={{ sx: { pt: '3px' } }}
          SelectProps={{
            MenuProps: {
              slotProps: { paper: { sx: { ...customScrollbar } } },
              sx: { ...customScrollbar, maxHeight: '40vh' }
            }
          }}
          inputProps={{
            sx: { padding: '12px 8px' }
          }}
          disabled={disabled}
          label={
            !options.length ? (
              <CircularProgress size={25} />
            ) : showTitle ? (
              ''
            ) : (
              `Rig${required ? '*' : ''}`
            )
          }
          size={'small'}
          select
          placeholder={'rig...'}
          value={value}
          variant={'outlined'}
          onChange={handleChange}>
          {options?.map(({ isTracked, rigName, rigId }) => (
            <MenuItem
              sx={{
                '&:hover': {
                  color: ({ palette }) => palette.primary.main
                }
              }}
              disabled={!isTracked}
              onClick={handleChange}
              key={`rig-select-${rigId}`}
              value={rigId}>
              {rigName}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Stack>
  );
}

RigSelect.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showTitle: PropTypes.bool,
  selectedRig: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool
};

RigSelect.defaultProps = {
  showTitle: false,
  required: false,
  onChange: undefined,
  selectedRig: null,
  helperText: null,
  disabled: false,
  error: false,
  id: 'rig-select'
};

export default RigSelect;

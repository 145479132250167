import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';

function ArrowBackButton({ children, onClick }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'arrow',
        display: 'flex',
        width: '32px',
        height: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flexShrink: 0,
        borderRadius: '8px',
        color: ({ palette }) => palette.primary.main,
        backgroundColor: ({ palette }) => palette.neutral.neutral01,
        transition: 'all 0.2s ease',
        '&:hover': {
          cursor: 'pointer',
          color: ({ palette }) => palette.primary.dark,
          backgroundColor: ({ palette }) => palette.neutral.neutral02
        }
      }}>
      {children}
    </Box>
  );
}

ArrowBackButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired
};

ArrowBackButton.defaultProps = {
  children: undefined
};

export default ArrowBackButton;

import React from 'react';
import { Button, Stack } from '@esgian/esgianui';
import { SUB_SECTIONS } from '@constants';
import { useUpdateSection } from '@hooks';
import { useSelector } from 'react-redux';
import { getMainPageSelectedSection } from '@store/features/filters/MainPageSlice/MainPageSlice';

function RigAnalyticsMenu() {
  const selectedSection = useSelector(getMainPageSelectedSection);

  const { updateMainPageSection } = useUpdateSection();

  const { OVERVIEW, ANALYTICS, DRILLING_ACTIVITIES, EQUIPMENT, POWER_MGMT } = SUB_SECTIONS;

  return (
    <Stack direction={'row'} spacing={1}>
      <Button
        sx={{ textTransform: 'capitalize' }}
        size={'small'}
        disableElevation
        onClick={() => updateMainPageSection(OVERVIEW)}
        color={selectedSection === OVERVIEW ? 'primary' : 'secondary'}
        variant={'contained'}>
        overview
      </Button>
      <Button
        sx={{ textTransform: 'capitalize' }}
        size={'small'}
        disableElevation
        onClick={() => updateMainPageSection(ANALYTICS)}
        color={selectedSection === ANALYTICS ? 'primary' : 'secondary'}
        variant={'contained'}>
        Analytics
      </Button>
      <Button
        size={'small'}
        sx={{ textTransform: 'capitalize' }}
        disableElevation
        onClick={() => updateMainPageSection(DRILLING_ACTIVITIES)}
        color={selectedSection === DRILLING_ACTIVITIES ? 'primary' : 'secondary'}
        variant={'contained'}>
        drilling activities
      </Button>
      <Button
        size={'small'}
        sx={{ textTransform: 'capitalize' }}
        disableElevation
        onClick={() => updateMainPageSection(EQUIPMENT)}
        color={selectedSection === EQUIPMENT ? 'primary' : 'secondary'}
        variant={'contained'}>
        Equipment
      </Button>
      <Button
        size={'small'}
        sx={{ textTransform: 'capitalize' }}
        disableElevation
        onClick={() => updateMainPageSection(POWER_MGMT)}
        color={selectedSection === POWER_MGMT ? 'primary' : 'secondary'}
        variant={'contained'}>
        power mgmt
      </Button>
    </Stack>
  );
}

RigAnalyticsMenu.propTypes = {};

RigAnalyticsMenu.defaultProps = {};

export default RigAnalyticsMenu;

import React, { useCallback, useMemo, useState } from 'react';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { VIEW_BY_TYPE } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBaselineComparisonSlice,
  setPeriodicOverviewFilters
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { TextWithTooltipIcon } from '@components';
import { getGeneralSlice } from '@store/features';
import { useBaselineComp } from '@hooks/useBaselineComp';
import PeriodOverviewKpis from '@components/Sections/BaselineComparisonSection/SubSections/PeriodicOverview/PeriodOverviewKpis';
import { BaselineComparisonChart } from '@components/Charts';
import DataTypeSelect from '@components/Inputs/DataTypeSelect';
import LookupSelect from '@components/Inputs/LookupSelect';
import { LOOKUP_OPERATIONS } from '../../../../../lookups';
import HourDaySelect from '../../../../Inputs/HourDaySelect';
import { useBaselineQueries } from '@hooks';

function PeriodicOverviewComparison() {
  const [loading, setLoading] = useState(true);
  const { getPeriodicOverviewSeries } = useBaselineComp();
  const dispatch = useDispatch();
  const { displayUnit } = useSelector(getGeneralSlice);

  const { baselineOverviewQueries } = useBaselineQueries();
  const mainData = baselineOverviewQueries[0].data;
  const baselineData = baselineOverviewQueries[1].data;
  const {
    periodicOverview: { dataType, operation, chartType, viewBy }
  } = useSelector(getBaselineComparisonSlice);

  const handleChange = useCallback((val) => {
    dispatch(setPeriodicOverviewFilters(val));
  }, []);

  const {
    mainSeries = [],
    baseSeries = [],
    categories = [],
    unit,
    max = 0
  } = useMemo(() => {
    if (!mainData || !baselineData) return {};
    setLoading(false);
    return getPeriodicOverviewSeries(mainData, baselineData);
  }, [displayUnit, mainData, baselineData, dataType, operation, viewBy]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant={'h6'}>Periodic overview comparison</Typography>
        </Stack>
        <TextWithTooltipIcon
          labelVariant={'subtitle1'}
          label={
            <Typography variant={'subtitle1'} bold color={'text.secondary'}>
              Insights comparing to the Baseline
            </Typography>
          }
          tooltipText={
            'The key figures displayed below show the difference compared to the baseline.'
          }
        />
        <PeriodOverviewKpis loading={loading} baselineData={baselineData} mainData={mainData} />
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparison over time
        </Typography>
        <BaselineComparisonChart
          dataType={dataType}
          chartType={chartType}
          viewBy={viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour'}
          chartActions={[
            <LookupSelect
              options={LOOKUP_OPERATIONS}
              label={'Operations'}
              key={'operations-select'}
              value={operation}
              onChange={(val) => {
                handleChange({ operation: val });
              }}
            />,

            <DataTypeSelect
              key={'data-type-select'}
              value={dataType}
              onChange={(val) => {
                handleChange({ dataType: val });
              }}
            />,
            <HourDaySelect
              key={'view-by-select'}
              value={viewBy}
              handleChange={(val) => {
                handleChange({ viewBy: val });
              }}
            />
          ]}
          yAxisTitle={unit}
          mainMax={max}
          loading={loading}
          mainSeries={mainSeries}
          baseSeries={baseSeries}
          categories={categories}
        />
      </Stack>
    </Paper>
  );
}

PeriodicOverviewComparison.propTypes = {};

PeriodicOverviewComparison.defaultProps = {};

export default PeriodicOverviewComparison;

import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@esgian/esgianui';
import { OPERATION_TYPE } from '@constants';

function OperationSelect({ disabled, value, onChange }) {
  return (
    <TextField
      select
      disabled={disabled}
      label={'Operation'}
      variant={'outlined'}
      inputProps={{ sx: { width: '150px', p: 1 } }}
      value={value || ''}
      onChange={({ target }) => onChange(parseInt(target.value))}>
      <MenuItem value={OPERATION_TYPE.ALL}>All</MenuItem>
      <MenuItem value={OPERATION_TYPE.DRILLING}>Drilling</MenuItem>
      <MenuItem value={OPERATION_TYPE.HOTEL_AND_UTILITIES}>Hotel & utilities</MenuItem>
    </TextField>
  );
}

OperationSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

OperationSelect.defaultProps = {
  disabled: false,
  value: null
};

export default OperationSelect;

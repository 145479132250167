import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField, Typography } from '@esgian/esgianui';
import { DATA_TYPE } from '@constants';

function DataTypeSelect({ disabled, value, onChange }) {
  return (
    <TextField
      select
      disabled={disabled}
      label={'Data Type'}
      variant={'outlined'}
      inputProps={{ sx: { width: '200px', p: 1, placeContent: 'center' } }}
      value={value || ''}
      onChange={({ target }) => onChange(parseInt(target.value))}>
      <MenuItem value={DATA_TYPE.ACCUMULATED}>
        <Typography variant={'body2'}>Accumulated data</Typography>
      </MenuItem>
      <MenuItem value={DATA_TYPE.DISCRETE}>
        <Typography variant={'body2'}>Discrete data</Typography>
      </MenuItem>
    </TextField>
  );
}

DataTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

DataTypeSelect.defaultProps = {
  disabled: false,
  value: null
};

export default DataTypeSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Stack, Typography } from '@esgian/esgianui';

function KeyFigureValueDisplay({
  unitValueVariant,
  boldMainValue,
  mainValueVariant,
  mainValue,
  unitValue,
  loading,
  unitTextColor
}) {
  return (
    <Stack>
      <Typography bold={boldMainValue} variant={mainValueVariant}>
        {loading ? <Skeleton height={'inherit'} sx={{ minWidth: '5em' }} /> : mainValue}
      </Typography>
      {typeof unitValue === 'string' ? (
        <Typography color={unitTextColor} variant={unitValueVariant}>
          {unitValue}
        </Typography>
      ) : (
        unitValue
      )}
    </Stack>
  );
}

KeyFigureValueDisplay.propTypes = {
  boldMainValue: PropTypes.bool,
  mainValueVariant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ]),
  mainValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitTextColor: PropTypes.string,
  loading: PropTypes.bool,
  unitValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  unitValueVariant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ])
};

KeyFigureValueDisplay.defaultProps = {
  boldMainValue: false,
  loading: false,
  mainValueVariant: 'h6',
  unitTextColor: 'text.primary',
  unitValueVariant: 'body2',
  mainValue: '',
  unitValue: ''
};

export default KeyFigureValueDisplay;

import { BaselineComparisonChart } from '@components/Charts';
import PowerMgmtComparisonKpis from '@components/Sections/BaselineComparisonSection/SubSections/PowerMgmtComparison/PowerMgmtComparisonKpis';
import { BASELINE_CHART_TYPE, DATA_TYPE, SFOC_OR_LOAD, VIEW_BY_TYPE } from '@constants';
import { Paper, Stack, Tab, Tabs, Typography } from '@esgian/esgianui';
import { useBaselineQueries } from '@hooks';
import { useBaselineComp } from '@hooks/useBaselineComp';
import {
  getBaselineComparisonSlice,
  setPowerMgmtFilters
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HourDaySelect from '../../../../Inputs/HourDaySelect';

function PowerMgmtComparison() {
  const dispatch = useDispatch();
  const { getPowerMgmtSeries } = useBaselineComp();
  const { baselinePowerMgmtQueries } = useBaselineQueries();
  const {
    powerMgmt: { dataUnit, dataType, viewBy }
  } = useSelector(getBaselineComparisonSlice);

  const handleChange = useCallback((val) => {
    dispatch(setPowerMgmtFilters(val));
  }, []);

  const mainData = baselinePowerMgmtQueries[0].data;
  const baselineData = baselinePowerMgmtQueries[1].data;

  const {
    mainSeries = [],
    baseSeries = [],
    categories = [],
    baseEngOnSeries = [],
    mainEngOnSeries = [],
    unit = '',
    max = 0,
    secMax = 0
  } = useMemo(() => {
    if (!mainData || !baselineData) return {};
    return getPowerMgmtSeries(mainData, baselineData);
  }, [mainData, baselineData, dataType, dataUnit, viewBy]);

  const loading = baselinePowerMgmtQueries?.some((item) => item.isFetching);
  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant={'h6'}>Power mgmt</Typography>
        </Stack>
        <Typography variant={'subtitle1'} bold color={'text.secondary'}>
          Comparing to the Baseline
        </Typography>
        <PowerMgmtComparisonKpis
          mainData={mainData}
          loading={loading}
          baselineData={baselineData}
        />
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'subtitle1'} bold color={'text.secondary'}>
            Comparison over time
          </Typography>
          <Tabs
            TabScrollButtonProps={{ size: 'small' }}
            TabIndicatorProps={{
              sx: {
                bottom: '2px',
                height: '1px'
              }
            }}
            sx={{
              mb: '4px !important',
              minHeight: 'min-content',
              height: '28px !important'
            }}
            onChange={(_, val) => handleChange({ dataUnit: val })}
            value={dataUnit}>
            <Tab
              sx={{
                textTransform: 'capitalize',
                '&.MuiButtonBase-root': {
                  padding: '8px 8px',
                  minHeight: 'fit-content',
                  minWidth: 'auto'
                }
              }}
              value={SFOC_OR_LOAD.SFOC}
              label={<Typography variant={'body2'}>Avg SFOC</Typography>}
            />
            <Tab
              sx={{
                textTransform: 'capitalize',
                '&.MuiButtonBase-root': {
                  padding: '8px 8px',
                  minHeight: 'fit-content',
                  minWidth: 'auto'
                }
              }}
              value={SFOC_OR_LOAD.LOAD}
              label={<Typography variant={'body2'}>Avg load</Typography>}
            />
          </Tabs>
        </Stack>
        <BaselineComparisonChart
          dataType={DATA_TYPE.DISCRETE}
          hideDailyComp
          chartActions={[
            <HourDaySelect
              key={'view-by-select'}
              value={viewBy}
              handleChange={(val) => {
                handleChange({ viewBy: val });
              }}
            />
          ]}
          viewBy={viewBy === VIEW_BY_TYPE.DAILY ? 'day' : 'hour'}
          yAxisTitle={dataUnit === SFOC_OR_LOAD.SFOC ? `g/${unit}` : '%'}
          chartType={BASELINE_CHART_TYPE.BY_TIME}
          loading={loading}
          mainSeries={mainSeries}
          mainMinMax={dataUnit === SFOC_OR_LOAD.SFOC ? { min: 150, max: 350 } : null}
          baseSeries={baseSeries}
          categories={categories}
          mainMax={max}
          secMax={secMax}
          secBaseSeries={baseEngOnSeries}
          secMainSeries={mainEngOnSeries}
        />
      </Stack>
    </Paper>
  );
}

PowerMgmtComparison.propTypes = {};

PowerMgmtComparison.defaultProps = {};

export default PowerMgmtComparison;

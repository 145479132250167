import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMainPageSection } from '@store/features/filters/MainPageSlice/MainPageSlice';

export const useUpdateSection = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateMainPageSection = useCallback(
    (sectionId) => {
      window.scrollTo(0, 0);
      dispatch(setMainPageSection(sectionId));
      searchParams.set('section', sectionId);
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  return { updateMainPageSection };
};

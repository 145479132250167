import { createSlice } from '@reduxjs/toolkit';
import { APP_NAME, DISPLAY_UNIT, STORE_VERSION } from '@constants';

const getSessionState = () => {
  try {
    const serializedState = sessionStorage.getItem(APP_NAME);
    if (!serializedState) return undefined;
    const stateObj = JSON.parse(serializedState);

    if (stateObj.version !== STORE_VERSION) {
      return undefined;
    }
    return stateObj.general;
  } catch (e) {
    return undefined;
  }
};

const initSideMenuItemsOpen = { gprOpen: false };

const initialState = {
  themeMode: !!JSON.parse(localStorage.getItem('esgian-theme-mode')),
  sideMenuOpen: !!JSON.parse(localStorage.getItem('esgian-menu-open')),
  dropDownMenuOpen: initSideMenuItemsOpen,
  version: 2.0,
  displayUnit: DISPLAY_UNIT.ENERGY
};

const GeneralSlice = createSlice({
  name: 'General',
  initialState: getSessionState() || initialState,
  reducers: {
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
      localStorage.setItem('esgian-theme-mode', `${action.payload}`);
    },
    setSideMenuOpen: (state, action) => {
      if (!action.payload) {
        GeneralSlice.caseReducers.closeAllSideMenuItems(state);
      }
      state.sideMenuOpen = action.payload;
      localStorage.setItem('esgian-menu-open', `${action.payload}`);
    },
    setSideMenuItemOpen: (state, action) => {
      state.dropDownMenuOpen = action.payload;
    },
    setDisplayUnit: (state, action) => {
      state.displayUnit = action.payload;
    },
    closeAllSideMenuItems: (state) => (state.dropDownMenuOpen = initSideMenuItemsOpen)
  }
});
export const getGeneralSlice = (state) => state.general;
export const getSideMenuOpen = (state) => state.general.sideMenuOpen;
export const getDisplayUnit = (state) => state.general.displayUnit;
export const getDropDownMenuOpen = (state) => state.general.dropDownMenuOpen;
export const getThemeMode = (state) => state.general.themeMode;

export const { setThemeMode, setSideMenuOpen, setDisplayUnit, setSideMenuItemOpen } =
  GeneralSlice.actions;

export default GeneralSlice.reducer;
